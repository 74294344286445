// ** React Imports **
import React, { useState, useEffect, Fragment, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// ** Redux Imports **
import { useDispatch, useSelector } from 'react-redux'
import {
  setTasks,
  setTotalTasks,
  setIsLoading,
  setCurrPage
} from '../context/actions/taskManagerAction'

// ** Ant Design Imports **
import moment from 'moment'
import {
  Checkbox,
  Input,
  Select,
  message,
  DatePicker,
  Radio,
  Button,
  Tooltip,
  Switch,
  InputNumber
} from 'antd'

// ** Custom Utility and Enum Imports **
import CalculationTypeEnum from '../utils/enums/CalculationTypeEnum'
import VendorTaskTypeEnum from '../utils/enums/VendorTaskTypeEnum'
import OptionsRestriction from '../utils/OptionsRestriction'
import getTaskHistoryColumns from '../utils/task-manager/getTaskHistoryColumns'
import formatIfDecimal from '../utils/task-manager/formatIfDecimal'
import { getColumns } from './AccrualManager/utils/column-utils'

// ** Custom Component Imports **
import DyanmicTable from '../components/DynamicTable'
import TableTag from '../components/TableTag'
import TaskManagerFilter from '../components/TaskManagerFilter'
import GlobalHistoryModal from '../components/GlobalHistoryModal'
import TaskActions from './AccrualManager/components/AccrualActions'

// ** Service Function Imports **
import {
  getVendorTasks,
  updateVendorTasks,
  updateVendorDetailsTasks,
  deleteVendorTasks,
  getSourceCoaOptions,
  getSourceVendorOptions,
  getFormsOptions,
  copyVendorTasks,
  massUpdateVendorTasks,
  massUpdateVendorTasksDetails,
  getTaskManagerDynamicColumn
} from '../services/baseAPI'

import { previewFilter } from '../services/TaskManagerApi'

// ** Service Function Imports **
import { getHistory } from '../services/HistoryAPI'

// ** Custom Hook Imports **
import useCompanySettings from '../hooks/useCompanySettings'
import useRolesAndPermissions from '../hooks/useRolesAndPermissions'
import useAccrualManagerstore from './AccrualManager/manager/tasks-store'

const { Option } = Select

const MAX_WIDTH = 400
const MIN_WIDTH = 100

/*eslint eqeqeq: "off"*/
const TaskManagerTable = () => {
  const navigate = useNavigate()
  const tasks = useSelector((state) => state.allTasks.tasks)
  const totalTasks = useSelector((state) => state.allTasks.totalTasks)
  const taskParams = useSelector((state) => state.allTasks.taskParams)
  const isLoading = useSelector((state) => state.allTasks.isLoading)
  const currPage = useSelector((state) => state.allTasks.currPage)
  const tsmPreviewMode = useSelector((state) => state.allTasks.previewMode)
  const tsmFilterFields = useSelector((state) => state.allTasks.filterFields)

  const dispatch = useDispatch()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const companyId = searchParams.get('companyId')
  const token = searchParams.get('token')
  const [editKey, setEditKey] = useState('')
  const [vendorList, setVendorList] = useState()
  const [subsidiaryList, setSubsidiaryList] = useState()
  const [deptList, setDeptList] = useState()
  const [glAccountList, setGlAccountList] = useState()
  const [formList, setFormList] = useState([])

  const [companyList, setCompanyList] = useState()
  const [interCompanyList, setInterCompanyList] = useState()
  const [channelList, setChannelList] = useState()
  const [locationList, setLocationList] = useState()
  const [productList, setProductList] = useState()
  const [regionList, setRegionList] = useState()
  const [classList, setClassList] = useState()
  const [currencyList, setCurrencyList] = useState()
  const [projectList, setProjectList] = useState()
  const [externalList, setExternalList] = useState([])
  const [internalList, setInternalList] = useState([])

  const [hasEditRole, setHasEditRole] = useState(false)
  const [totalItemPerPage, setTotalItemPerPage] = useState(100)

  const [selectedRows, setSelectedRows] = useState([])
  const [columnValue, setColumnValue] = useState({
    key: '',
    value: null
  })
  const [columnVendorName, setColumnVendorName] = useState({
    name: '',
    vendor_id: null
  })
  const [columnValueVendorTaskDetails, setColumnValueTaskDetails] = useState({})
  const [applyToSelectedRows, setApplyToSelectedRows] = useState([])
  const [columnHeaders, setColumnHeaders] = useState([])
  const [showHeader, setShowHeader] = useState(false)

  const searchTermRef = useRef('')
  const activeFilterRef = useRef({ id: 'unreviewed', label: 'Inactive Lines' })
  const buttonRef = useRef(null)
  const bulkUpdateDataRef = useRef({})
  const [userTimezone] = useState('US/Pacific')
  const [userDateTimeFormat] = useState('YYYY-MM-DD h:mm a')

  const { data } = useCompanySettings()
  const { setColumnFields } = useAccrualManagerstore()

  const RP = useRolesAndPermissions()

  const removeFocus = () => {
    buttonRef.current.blur() // removing focus
  }

  const hasEditableKeys = [
    'name',
    'email',
    'internal_email',
    'cc_email',
    'internal_cc_email',
    'subsidiary',
    'subsidiary_currency',
    'glaccount_debit',
    'glaccount_credit',
    'department_debit',
    'department_credit',
    'company_debit',
    'company_credit',
    'intercompany_debit',
    'intercompany_credit',
    'channel_debit',
    'channel_credit',
    'location_debit',
    'location_credit',
    'class_debit',
    'class_credit',
    'product_debit',
    'product_credit',
    'project_debit',
    'project_credit',
    'region_debit',
    'region_credit',
    'transaction_currency',
    'po_owner',
    'po_line_status',
    'service_start_date',
    'service_end_date',
    'po_line_amount',
    'invoiced_amount',
    'po_line_amount_cancelled',
    'po_balance',
    'po_number',
    'po_line_description',
    'po_line_id',
    'po_id',
    'po_header_description',
    'po_header_amount',
    'po_line_amount_usd',
    'po_balance_usd'
  ]

  useEffect(() => {
    if (data && !data.display_task_tab) {
      navigate('/error?message=Forbidden&status=403')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.display_task_tab])

  const isEditable = (item) => {
    if (item.internal_source_purchase_order_id && !item.parent_id) {
      return false
    }

    return item.task_inactive == 0
  }

  //AC-28 This are the new editable rule for the field
  //all types(PO/Vendor/PO Copy/Vendor Copy) for this field/column are editable
  //inactive tasks are not editable
  const isEditableNewRule = (item) => {
    return item.task_inactive == 0
  }

  //AC-3211 -> subsidiary of copied item is editable
  const isSubsidaryEditable = (item) => {
    return item.parent_id
  }

  const canCopy = (hasRole, parentId) => {
    if (hasRole && !parentId) {
      return true
    }
    return false
  }

  const canDelete = (hasRole, parent_id) => {
    if (hasRole && parent_id) {
      return true
    }
    return false
  }

  const reviewString = (item) => {
    if (item == '1') {
      return 'Yes'
    } else if (item == '0' || item == '' || item == null) {
      return 'No'
    }

    return item
  }

  const getColumnHeaders = () => {
    //ENG-1115 default columns, cannot be removed
    getTaskManagerDynamicColumn().then((res) => {
      if (res && res.data) {
        setColumnHeaders(res.data)
        setColumnFields(res.data) //get label for immutable fields
      }
    })
  }

  const grayLabel = (item, isDisabled) => {
    const display = (
      <div
        id='table-label'
        style={{ color: isDisabled && '#abaaaa', maxWidth: MAX_WIDTH }}
      >
        {item}
      </div>
    )

    if (displayTextWidth(item) > MAX_WIDTH) {
      return (
        <Tooltip placement='topLeft' title={item}>
          {display}
        </Tooltip>
      )
    }
    return display
  }

  const displayTextWidth = (text) => {
    const canvas =
      document.createElement('canvas') ||
      (displayTextWidth.canvas = document.createElement('canvas'))
    const context = canvas.getContext('2d')
    if (context) {
      context.font = '13px Open Sans'
      return context.measureText(text).width + 45
    }
    return MIN_WIDTH
  }

  const setColumns = () => {
    let columns = [
      {
        title: 'Action',
        key: '',
        fixed: 'left',
        width: 90,
        render: (item, record) => (
          <TaskActions
            isOutOfSync={
              record.is_out_of_sync === 1 &&
              RP.USER_HAS_IMPORT_ACCRUAL_MANAGER_PERMISSION
            }
            // isOutOfSync={
            //   !['api'].includes(record?.vendor_task_details?.data_source) &&
            //   ['Vendor', 'PO'].includes(record?.task_type)
            // }
            taskId={item.vendor_task_id}
            taskManagerColumns={getColumns({
              columnHeaders,
              externalList,
              internalList,
              userTimezone,
              userDateTimeFormat
            })}
            // taskManagerColumns={setColumns()
            //   .slice(2)
            //   .map((col) => {
            //     return {
            //       ...col,
            //       fixed: undefined
            //     }
            //   })}
            onClickCopy={onClickCopy(item, record)}
            onClickRemove={onClickRemove(item)}
            disabledCopy={
              !canCopy(RP.USER_COPY_TASK_PERMISSION, record.parent_id)
            }
            disabledDelete={
              !canDelete(RP.USER_DELETE_TASK_PERMISSION, record.parent_id)
            }
            historyModal={(setShowPopover) => {
              return (
                <GlobalHistoryModal
                  overrideModalTitle='History'
                  disableHistoryModal={!RP.USER_HAS_HISTORY_SHOW_PERMISSION}
                  reloadData={() => {
                    return getHistory(
                      record.vendor_task_id
                        ? record.vendor_task_id
                        : record.vendor_task_details.vendor_task_id
                    )
                  }}
                  columns={getTaskHistoryColumns()}
                  hasExportButton={false}
                  overrideExportButtonLabel='Export to CSV'
                  isWithLabel={true}
                  onCancel={() => {
                    setShowPopover(false)
                  }}
                />
              )
            }}
          />
        )
      },
      {
        title: () => (
          <Checkbox
            defaultChecked={false}
            checked={
              selectedRows.length > 0 &&
              selectedRows.length ===
                tasks.filter((task) => task.task_inactive === 0).length &&
              tasks.length > 0
            }
            onChange={onChangeCheckboxAll(tasks)}
            disabled={!RP.USER_MASS_UPDATE_TASK_PERMISSION}
          />
        ),
        key: '',
        fixed: 'left',
        width: 50,
        render: (row, record) => (
          <span className='table-action'>
            <Checkbox
              defaultChecked={false}
              checked={isRowSelected(row)}
              onChange={onChangeSelectCheckbox(row)}
              disabled={
                !RP.USER_MASS_UPDATE_TASK_PERMISSION ||
                record.task_inactive === 1
              }
              data-cy={`tsm-table-select-row-action-checkbox-${row.id}`}
              data-testid={`tsm-table-select-row-action-checkbox-${row.id}`}
            />
          </span>
        )
      }
    ]

    columnHeaders.map((item) => {
      const apiName = item.api_name ?? 'no-api-name'

      if (item.api_name == 'reviewed') {
        columns.push({
          title: item.label, // ENG-1249 - Change title to dynamic
          dataIndex: 'reviewed',
          key: 'reviewed',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Radio.Group
                        onChange={onChangeSetColumnValue('reviewed')}
                        defaultValue={item}
                        data-cy={`tsm-table-reviewed-radio-group-${record.id}`}
                        data-testid={`tsm-table-reviewed-radio-group-${record.id}`}
                      >
                        <Radio.Button value='1'>Yes</Radio.Button>
                        <Radio.Button value='0'>No</Radio.Button>
                      </Radio.Group>
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          defaultValue={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'reviewed',
                            record.id
                          )}
                          data-cy={`tsm-table-reviewed-checkbox-${record.id}`}
                          data-testid={`tsm-table-reviewed-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>

                      <div className='bulk-buttons'>
                        <Button
                          size='small'
                          onClick={onClickCancel}
                          data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                          data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                          ghost
                        >
                          Cancel
                        </Button>
                        <Button
                          size='small'
                          onClick={onClickApplyChangesToSelectedRows(
                            'reviewed',
                            record,
                            item
                          )}
                          data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <Checkbox
                      defaultChecked={item == '1' ? true : false}
                      onChange={onChangeCheckbox('reviewed', record)}
                    />
                  )}
                </>
              ) : (
                <span>{reviewString(item)}</span>
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'active') {
        columns.push({
          title: item.label, // ENG-1249 - Change title to dynamic
          dataIndex: 'active',
          key: 'active',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id &&
              selectedRows.length > 1 &&
              record.task_inactive === 0 ? (
                <div
                  className='bulk-column'
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  <Switch
                    key={record.id}
                    style={{ marginBottom: 10 }}
                    onChange={onSwitchSetColumnValue('active')}
                    defaultChecked={record.active === 1}
                  />

                  <div className='checkbox-lbl'>
                    <Checkbox
                      defaultChecked={false}
                      defaultValue={false}
                      onChange={onChangeCheckboxApplyToSelectedRow(
                        'active',
                        record.id
                      )}
                    />
                    <label>Update {selectedRows.length} selected items</label>
                  </div>

                  <div className='bulk-buttons'>
                    <Button size='small' onClick={onClickCancel} ghost>
                      Cancel
                    </Button>
                    <Button
                      size='small'
                      onClick={onClickApplyChangesToSelectedRows(
                        'active',
                        record,
                        item
                      )}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              ) : record.task_inactive === 1 ? (
                <Tooltip title='You cannot activate this line because the vendor is inactive or the PO is no longer open.'>
                  <Switch
                    key={`${record.id}-active-${record.active}`}
                    disabled={true}
                    onClick={removeFocus}
                    ref={buttonRef}
                    checked={record.active === 1}
                    onChange={onChangeSwitch('active', record, record.active)}
                  />
                </Tooltip>
              ) : ((record.external_accrual === 4 ||
                  record.external_accrual === 1) &&
                  (record.internal_accrual === 4 ||
                    record.internal_accrual === 1) &&
                  (record.calculation === 5 || record.calculation === 1)) ||
                record.external_accrual === null ||
                record.internal_accrual === null ||
                record.calculation === null ? (
                //ENG-9183 User should not be able to activate if the values of Vendor, Internal, or Calculation is null
                <Tooltip title='Please set at least one accrual type (e.g. Vendor, Internal, or Calculation) to activate this line.'>
                  <Switch
                    key={`${record.id}-active-${record.active}`}
                    onClick={removeFocus}
                    ref={buttonRef}
                    checked={record.active === 1}
                    //ENG-9183 added condition on onchange so user will not be able to activate
                    onChange={
                      record.active === 1
                        ? onChangeSwitch('active', record, record.active)
                        : undefined
                    }
                  />
                </Tooltip>
              ) : (
                <Switch
                  key={`${record.id}-active-${record.active}`}
                  onClick={removeFocus}
                  ref={buttonRef}
                  checked={record.active === 1}
                  onChange={onChangeSwitch('active', record, record.active)}
                />
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'name') {
        columns.push({
          title: item.label, // ENG-1249 - Change title to dynamic
          dataIndex: ['vendor_task_details', 'name'],
          key: 'vendor_name',
          width: 400,
          render: (item, record) => (
            <Fragment>
              {editKey === record.id &&
              isEditable(record) &&
              !record.internal_source_vendor_id ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Select
                        showSearch
                        defaultValue={item}
                        onChange={onChangeColumnVendorName('name', 'vendor_id')}
                        onClick={() => {
                          if (!vendorList) getVendorList()
                        }}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        {vendorList ? (
                          <Fragment>
                            <Option />
                            {vendorList.map((item) => (
                              <Option
                                key={item.g_source_system_id}
                                value={item.value}
                              >
                                {item.value}
                              </Option>
                            ))}
                          </Fragment>
                        ) : (
                          <Option disabled>Loading options...</Option>
                        )}
                      </Select>
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'name',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorName(
                              'name',
                              'vendor_id',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : isEditable(record) ? (
                    <Select
                      showSearch
                      defaultValue={item}
                      onClick={() => {
                        if (!vendorList) getVendorList()
                      }}
                      onSelect={onChangeSelector(
                        'name',
                        record,
                        true,
                        'vendor_id'
                      )}
                      allowClear
                      onClear={onClearSelector(
                        'name',
                        record,
                        true,
                        'vendor_id'
                      )}
                      data-cy={`tsm-table-${apiName}-select-${record.id}`}
                      data-testid={`tsm-table-${apiName}-select-${record.id}`}
                    >
                      {vendorList ? (
                        <Fragment>
                          <Option />
                          {vendorList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.value}
                            >
                              {item.value}
                            </Option>
                          ))}
                        </Fragment>
                      ) : (
                        <Option disabled>Loading options...</Option>
                      )}
                    </Select>
                  ) : (
                    item &&
                    grayLabel(
                      item,
                      !(isEditable(record) && !record.internal_source_vendor_id)
                    )
                  )}
                </>
              ) : (
                item &&
                grayLabel(
                  item,
                  !(isEditable(record) && !record.internal_source_vendor_id)
                )
              )}
              {/* replace is_out_of_sync, use data_source instead */}
              {record.is_out_of_sync === 1 && (
                <Tooltip title='Line is out-of-sync with source records.'>
                  <div className='out-of-sync-tooltip' />
                </Tooltip>
              )}
            </Fragment>
          )
        })
      } else if (
        item.api_name == 'task_inactive' ||
        item.api_name == 'is_active' ||
        item.api_name == 'g_generate_accruals'
      ) {
        columns.push({
          title: item.label,
          dataIndex: item.api_name,
          key: item.api_name,
          width: displayTextWidth(item.label),
          render: (item) => (
            <Fragment>
              <span>
                {grayLabel(
                  item == '1' ? 'Yes' : item == '0' ? 'No' : item,
                  true
                )}
              </span>
            </Fragment>
          )
        })
      } else if (item.api_name == 'external_accrual') {
        columns.push({
          title: item.label,
          dataIndex: 'external_accrual',
          key: 'external_accrual',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {record.task_inactive === 0 ? (
                editKey === record.id ? (
                  <>
                    {selectedRows.length > 1 && isRowSelected(record) ? (
                      <div className='bulk-column'>
                        <Select
                          defaultValue={OptionsRestriction.getValueFromKey(
                            item,
                            externalList
                          )}
                          valueKey='id'
                          onChange={onChangePickListSetColumnValue(
                            'external_accrual'
                          )}
                          data-cy={`tsm-table-${apiName}-select-${record.id}`}
                          data-testid={`tsm-table-${apiName}-select-${record.id}`}
                        >
                          {OptionsRestriction.getKeyValueList(
                            record,
                            externalList
                          ).map((options, index) => (
                            <Option key={index} value={options.id}>
                              {options.value}
                            </Option>
                          ))}
                        </Select>
                        <br />
                        <div className='checkbox-lbl'>
                          <Checkbox
                            defaultChecked={false}
                            onChange={onChangeCheckboxApplyToSelectedRow(
                              'external_accrual',
                              record.id
                            )}
                            data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                            data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                          />
                          <label>
                            Update {selectedRows.length} selected items
                          </label>
                        </div>
                        <Fragment>
                          <div className='bulk-buttons'>
                            <Button
                              size='small'
                              onClick={onClickCancel}
                              data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                              data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                              ghost
                            >
                              Cancel
                            </Button>
                            <Button
                              type='primary'
                              size='small'
                              onClick={onClickApplyChangesToSelectedRows(
                                'external_accrual',
                                record,
                                item
                              )}
                              data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                              data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            >
                              Apply
                            </Button>
                          </div>
                        </Fragment>
                      </div>
                    ) : (
                      <Select
                        defaultValue={OptionsRestriction.getValueFromKey(
                          item,
                          externalList
                        )}
                        valueKey='id'
                        onSelect={onChangeSelector('external_accrual', record)}
                        allowClear
                        onClear={onClearSelector('external_accrual', record)}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        <Option />
                        {OptionsRestriction.getKeyValueList(
                          record,
                          externalList
                        ).map((options, index) => (
                          <Option key={index} value={options.id}>
                            {options.value}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </>
                ) : (
                  OptionsRestriction.getValueFromKey(item, externalList)
                )
              ) : (
                grayLabel(
                  OptionsRestriction.getValueFromKey(item, externalList),
                  !(isEditable(record) && !record.internal_source_vendor_id)
                )
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'internal_accrual') {
        columns.push({
          title: item.label,
          dataIndex: 'internal_accrual',
          key: 'internal_accrual',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {record.task_inactive === 0 ? (
                editKey === record.id ? (
                  <>
                    {selectedRows.length > 1 && isRowSelected(record) ? (
                      <div className='bulk-column'>
                        <Select
                          defaultValue={OptionsRestriction.getValueFromKey(
                            item,
                            internalList
                          )}
                          valueKey='id'
                          onChange={onChangePickListSetColumnValue(
                            'internal_accrual'
                          )}
                          data-cy={`tsm-table-${apiName}-select-${record.id}`}
                          data-testid={`tsm-table-${apiName}-select-${record.id}`}
                        >
                          {OptionsRestriction.getKeyValueList(
                            record,
                            internalList
                          ).map((options, index) => (
                            <Option key={index} value={options.id}>
                              {options.value}
                            </Option>
                          ))}
                        </Select>
                        <br />
                        <div className='checkbox-lbl'>
                          <Checkbox
                            defaultChecked={false}
                            onChange={onChangeCheckboxApplyToSelectedRow(
                              'internal_accrual',
                              record.id
                            )}
                            data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                            data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                          />
                          <label>
                            Update {selectedRows.length} selected items
                          </label>
                        </div>
                        <Fragment>
                          <div className='bulk-buttons'>
                            <Button
                              size='small'
                              onClick={onClickCancel}
                              data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                              data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                              ghost
                            >
                              Cancel
                            </Button>
                            <Button
                              type='primary'
                              size='small'
                              onClick={onClickApplyChangesToSelectedRows(
                                'internal_accrual',
                                record,
                                item
                              )}
                              data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                              data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            >
                              Apply
                            </Button>
                          </div>
                        </Fragment>
                      </div>
                    ) : (
                      <Select
                        defaultValue={OptionsRestriction.getValueFromKey(
                          item,
                          externalList
                        )}
                        onSelect={onChangeSelector('internal_accrual', record)}
                        allowClear
                        onClear={onClearSelector('internal_accrual', record)}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        <Option />
                        {OptionsRestriction.getKeyValueList(
                          record,
                          internalList
                        ).map((options, index) => (
                          <Option key={index} value={options.id}>
                            {options.value}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </>
                ) : (
                  OptionsRestriction.getValueFromKey(item, internalList)
                )
              ) : (
                grayLabel(
                  OptionsRestriction.getValueFromKey(item, externalList),
                  !(isEditable(record) && !record.internal_source_vendor_id)
                )
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'calculation') {
        // Default dataIndex for calculation modal
        let dataIndex = 'calculation'

        // Check if source is vtd... if vtd, update dataIndex value
        if (item.source === 'vendor_task_details') {
          dataIndex = ['vendor_task_details', 'calculation']
        } // Else, retain the default dataIndex value

        columns.push({
          title: item.label,
          dataIndex: dataIndex,
          key: 'calculation',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {record.task_inactive === 0 ? (
                editKey === record.id ? (
                  <>
                    {selectedRows.length > 1 && isRowSelected(record) ? (
                      <div className='bulk-column'>
                        <Select
                          defaultValue={CalculationTypeEnum.getDscpFromKey(
                            item
                          )}
                          valueKey='id'
                          onChange={onChangePickListSetColumnValue(
                            'calculation'
                          )}
                          data-cy={`tsm-table-${apiName}-select-${record.id}`}
                          data-testid={`tsm-table-${apiName}-select-${record.id}`}
                        >
                          {CalculationTypeEnum.getKeyValueList(record).map(
                            (options, index) => (
                              <Option key={index} value={options.id}>
                                {options.value}
                              </Option>
                            )
                          )}
                        </Select>
                        <br />
                        <div className='checkbox-lbl'>
                          <Checkbox
                            defaultChecked={false}
                            onChange={onChangeCheckboxApplyToSelectedRow(
                              'calculation',
                              record.id
                            )}
                            data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                            data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                          />
                          <label>
                            Update {selectedRows.length} selected items
                          </label>
                        </div>
                        <Fragment>
                          <div className='bulk-buttons'>
                            <Button
                              size='small'
                              onClick={onClickCancel}
                              data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                              data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                              ghost
                            >
                              Cancel
                            </Button>
                            <Button
                              type='primary'
                              size='small'
                              onClick={onClickApplyChangesToSelectedRows(
                                'calculation',
                                record,
                                item
                              )}
                              data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                              data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            >
                              Apply
                            </Button>
                          </div>
                        </Fragment>
                      </div>
                    ) : (
                      <Select
                        defaultValue={CalculationTypeEnum.getDscpFromKey(item)}
                        onSelect={onChangeSelector('calculation', record)}
                        allowClear
                        onClear={onClearSelector('calculation', record)}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        <Option />
                        {CalculationTypeEnum.getKeyValueList(record).map(
                          (options, index) => (
                            <Option key={index} value={options.id}>
                              {options.value}
                            </Option>
                          )
                        )}
                      </Select>
                    )}
                  </>
                ) : (
                  CalculationTypeEnum.getDscpFromKey(item)
                )
              ) : (
                grayLabel(
                  CalculationTypeEnum.getDscpFromKey(item),
                  !(isEditable(record) && !record.internal_source_vendor_id)
                )
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'email') {
        columns.push({
          title: item.label,
          dataIndex: 'email',
          key: 'email',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {record.task_inactive === 0 ? (
                editKey === record.id ? (
                  <>
                    {selectedRows.length > 1 && isRowSelected(record) ? (
                      <div className='bulk-column'>
                        <Input
                          defaultValue={item}
                          onChange={onChangeInputField('email')}
                          data-cy={`tsm-table-${apiName}-input-${record.id}`}
                          data-testid={`tsm-table-${apiName}-input-${record.id}`}
                        />
                        <br />
                        <div className='checkbox-lbl'>
                          <Checkbox
                            defaultChecked={false}
                            onChange={onChangeCheckboxApplyToSelectedRow(
                              'email',
                              record.id
                            )}
                            data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                            data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                          />
                          <label>
                            Update {selectedRows.length} selected items
                          </label>
                        </div>
                        <Fragment>
                          <div className='bulk-buttons'>
                            <Button
                              size='small'
                              onClick={onClickCancel}
                              data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                              data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                              ghost
                            >
                              Cancel
                            </Button>
                            <Button
                              type='primary'
                              size='small'
                              onClick={onClickApplyChangesToSelectedRows(
                                'email',
                                record,
                                item
                              )}
                              data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                              data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            >
                              Apply
                            </Button>
                          </div>
                        </Fragment>
                      </div>
                    ) : (
                      <Input
                        defaultValue={item}
                        onPressEnter={onPressEnterField('email', record, false)}
                        data-cy={`tsm-table-${apiName}-input-${record.id}`}
                        data-testid={`tsm-table-${apiName}-input-${record.id}`}
                      ></Input>
                    )}
                  </>
                ) : (
                  grayLabel(item, !true)
                )
              ) : (
                grayLabel(item, !true)
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'internal_email') {
        columns.push({
          title: item.label,
          dataIndex: 'internal_email',
          key: 'internal_email',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Input
                        defaultValue={item}
                        onChange={onChangeInputField('internal_email')}
                        data-cy={`tsm-table-${apiName}-input-${record.id}`}
                        data-testid={`tsm-table-${apiName}-input-${record.id}`}
                      />
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'internal_email',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRows(
                              'internal_email',
                              record,
                              item
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Input
                      defaultValue={item}
                      onPressEnter={onPressEnterField(
                        'internal_email',
                        record,
                        false
                      )}
                      data-cy={`tsm-table-${apiName}-input-${record.id}`}
                      data-testid={`tsm-table-${apiName}-input-${record.id}`}
                    ></Input>
                  )}
                </>
              ) : (
                grayLabel(item, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'cc_email') {
        columns.push({
          title: item.label,
          dataIndex: 'cc_email',
          key: 'cc_email',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Input
                        defaultValue={item}
                        onChange={onChangeInputField('cc_email')}
                        data-cy={`tsm-table-${apiName}-input-${record.id}`}
                        data-testid={`tsm-table-${apiName}-input-${record.id}`}
                      />
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'cc_email',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRows(
                              'cc_email',
                              record,
                              item
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Input
                      defaultValue={item}
                      onPressEnter={onPressEnterField('cc_email', record)}
                      data-cy={`tsm-table-${apiName}-input-${record.id}`}
                      data-testid={`tsm-table-${apiName}-input-${record.id}`}
                    ></Input>
                  )}
                </>
              ) : (
                grayLabel(item, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'internal_cc_email') {
        columns.push({
          title: item.label,
          dataIndex: 'internal_cc_email',
          key: 'internal_cc_email',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Input
                        defaultValue={item}
                        onChange={onChangeInputField('internal_cc_email')}
                        data-cy={`tsm-table-${apiName}-input-${record.id}`}
                        data-testid={`tsm-table-${apiName}-input-${record.id}`}
                      />
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'internal_cc_email',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRows(
                              'internal_cc_email',
                              record,
                              item
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Input
                      defaultValue={item}
                      // onChange={onChangeField("g_transaction_group", record)}
                      onPressEnter={onPressEnterField(
                        'internal_cc_email',
                        record
                      )}
                      data-cy={`tsm-table-${apiName}-input-${record.id}`}
                      data-testid={`tsm-table-${apiName}-input-${record.id}`}
                    ></Input>
                  )}
                </>
              ) : (
                grayLabel(item, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'subsidiary') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'subsidiary'],
          key: 'subsidiary',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {(editKey === record.id && isSubsidaryEditable(record) && (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Select
                        showSearch
                        defaultValue={item}
                        onChange={onChangePickListSetColumnValueVendorTaskDetails(
                          'subsidiary',
                          'subsidiary_id'
                        )}
                        optionFilterProp='children'
                        onClick={() => {
                          if (!subsidiaryList) getSubsidiaryList()
                        }}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        {subsidiaryList ? (
                          subsidiaryList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))
                        ) : (
                          <Option disabled>Loading options...</Option>
                        )}
                      </Select>
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'subsidiary',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'subsidiary',
                              'subsidiary_id',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Select
                      showSearch
                      defaultValue={item}
                      onSelect={onChangeSelector(
                        'subsidiary',
                        record,
                        true,
                        'subsidiary_id'
                      )}
                      allowClear
                      onClear={onClearSelector(
                        'subsidiary',
                        record,
                        true,
                        'subsidiary_id'
                      )}
                      optionFilterProp='children'
                      onClick={() => {
                        if (!subsidiaryList) getSubsidiaryList()
                      }}
                      data-cy={`tsm-table-${apiName}-select-${record.id}`}
                      data-testid={`tsm-table-${apiName}-select-${record.id}`}
                    >
                      {subsidiaryList ? (
                        <Fragment>
                          <Option />
                          {subsidiaryList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))}
                        </Fragment>
                      ) : (
                        <Option disabled>Loading options...</Option>
                      )}
                    </Select>
                  )}
                </>
              )) ||
                grayLabel(item, !isSubsidaryEditable(record))}
            </Fragment>
          )
        })
      } else if (item.api_name == 'subsidiary_currency') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'subsidiary_currency'],
          key: 'subsidiary_currency',
          width: displayTextWidth(item.label),
          render: (item) => <Fragment>{grayLabel(item, true)}</Fragment>
        })
      } else if (item.api_name == 'glaccount_debit') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'glaccount_debit'],
          key: 'glaccount_debit',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Select
                        showSearch
                        defaultValue={item}
                        onChange={onChangePickListSetColumnValueVendorTaskDetails(
                          'glaccount_debit',
                          'glaccount_debit_id'
                        )}
                        optionFilterProp='children'
                        onClick={() => {
                          if (!glAccountList) getGlAccountList()
                        }}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        {glAccountList ? (
                          glAccountList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))
                        ) : (
                          <Option disabled>Loading options...</Option>
                        )}
                      </Select>
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'glaccount_debit',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'glaccount_debit',
                              'glaccount_debit_id',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Select
                      showSearch
                      defaultValue={item}
                      onSelect={onChangeSelector(
                        'glaccount_debit',
                        record,
                        true,
                        'glaccount_debit_id'
                      )}
                      optionFilterProp='children'
                      allowClear
                      onClear={onClearSelector(
                        'glaccount_debit',
                        record,
                        true,
                        'glaccount_debit_id'
                      )}
                      onClick={() => {
                        if (!glAccountList) getGlAccountList()
                      }}
                      data-cy={`tsm-table-${apiName}-select-${record.id}`}
                      data-testid={`tsm-table-${apiName}-select-${record.id}`}
                    >
                      {glAccountList ? (
                        <Fragment>
                          <Option />
                          {glAccountList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))}
                        </Fragment>
                      ) : (
                        <Option disabled>Loading options...</Option>
                      )}
                    </Select>
                  )}
                </>
              ) : (
                grayLabel(item, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'glaccount_credit') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'glaccount_credit'],
          key: 'glaccount_credit',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Select
                        showSearch
                        defaultValue={item}
                        onChange={onChangePickListSetColumnValueVendorTaskDetails(
                          'glaccount_credit',
                          'glaccount_credit_id'
                        )}
                        optionFilterProp='children'
                        onClick={() => {
                          if (!glAccountList) getGlAccountList()
                        }}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        {glAccountList ? (
                          glAccountList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))
                        ) : (
                          <Option disabled>Loading options...</Option>
                        )}
                      </Select>
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'glaccount_credit',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'glaccount_credit',
                              'glaccount_credit_id',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Select
                      showSearch
                      defaultValue={item}
                      onSelect={onChangeSelector(
                        'glaccount_credit',
                        record,
                        true,
                        'glaccount_credit_id'
                      )}
                      optionFilterProp='children'
                      allowClear
                      onClear={onClearSelector(
                        'glaccount_credit',
                        record,
                        true,
                        'glaccount_credit_id'
                      )}
                      onClick={() => {
                        if (!glAccountList) getGlAccountList()
                      }}
                      data-cy={`tsm-table-${apiName}-select-${record.id}`}
                      data-testid={`tsm-table-${apiName}-select-${record.id}`}
                    >
                      {glAccountList ? (
                        <Fragment>
                          <Option />
                          {glAccountList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))}
                        </Fragment>
                      ) : (
                        <Option disabled>Loading options...</Option>
                      )}
                    </Select>
                  )}
                </>
              ) : (
                grayLabel(item, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'department_debit') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'department_debit'],
          key: 'department_debit',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Select
                        showSearch
                        defaultValue={item}
                        onChange={onChangePickListSetColumnValueVendorTaskDetails(
                          'department_debit',
                          'department_debit_id'
                        )}
                        optionFilterProp='children'
                        onClick={() => {
                          if (!deptList) getDeptList()
                        }}
                      >
                        {deptList ? (
                          deptList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))
                        ) : (
                          <Option disabled>Loading options...</Option>
                        )}
                      </Select>
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'department_debit',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button size='small' onClick={onClickCancel} ghost>
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'department_debit',
                              'department_debit_id',
                              record
                            )}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Select
                      showSearch
                      defaultValue={item}
                      onSelect={onChangeSelector(
                        'department_debit',
                        record,
                        true,
                        'department_debit_id'
                      )}
                      optionFilterProp='children'
                      allowClear
                      onClear={onClearSelector(
                        'department_debit',
                        record,
                        true,
                        'department_debit_id'
                      )}
                      onClick={() => {
                        if (!deptList) getDeptList()
                      }}
                    >
                      {deptList ? (
                        <Fragment>
                          <Option />
                          {deptList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))}
                        </Fragment>
                      ) : (
                        <Option disabled>Loading options...</Option>
                      )}
                    </Select>
                  )}
                </>
              ) : (
                grayLabel(item, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'department_credit') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'department_credit'],
          key: 'department_credit',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Select
                        showSearch
                        defaultValue={item}
                        onChange={onChangePickListSetColumnValueVendorTaskDetails(
                          'department_credit',
                          'department_credit_id'
                        )}
                        optionFilterProp='children'
                        onClick={() => {
                          if (!deptList) getDeptList()
                        }}
                      >
                        {deptList ? (
                          deptList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))
                        ) : (
                          <Option disabled>Loading options...</Option>
                        )}
                      </Select>
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'department_credit',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button size='small' onClick={onClickCancel} ghost>
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'department_credit',
                              'department_credit_id',
                              record
                            )}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Select
                      showSearch
                      defaultValue={item}
                      onSelect={onChangeSelector(
                        'department_credit',
                        record,
                        true,
                        'department_credit_id'
                      )}
                      optionFilterProp='children'
                      allowClear
                      onClear={onClearSelector(
                        'department_credit',
                        record,
                        true,
                        'department_credit_id'
                      )}
                      onClick={() => {
                        if (!deptList) getDeptList()
                      }}
                    >
                      {deptList ? (
                        <Fragment>
                          <Option />
                          {deptList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))}
                        </Fragment>
                      ) : (
                        <Option disabled>Loading options...</Option>
                      )}
                    </Select>
                  )}
                </>
              ) : (
                grayLabel(item, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'company_debit') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'company_debit'],
          key: 'company_debit',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Select
                        showSearch
                        defaultValue={item}
                        onChange={onChangePickListSetColumnValueVendorTaskDetails(
                          'company_debit',
                          'company_debit_id'
                        )}
                        optionFilterProp='children'
                        onClick={() => {
                          if (!companyList) getCompanyList()
                        }}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        {companyList ? (
                          companyList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))
                        ) : (
                          <Option disabled>Loading options...</Option>
                        )}
                      </Select>
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'company_debit',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button size='small' onClick={onClickCancel} ghost>
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'company_debit',
                              'company_debit_id',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Select
                      showSearch
                      defaultValue={item}
                      onSelect={onChangeSelector(
                        'company_debit',
                        record,
                        true,
                        'company_debit_id'
                      )}
                      optionFilterProp='children'
                      allowClear
                      onClear={onClearSelector(
                        'company_debit',
                        record,
                        true,
                        'company_debit_id'
                      )}
                      onClick={() => {
                        if (!companyList) getCompanyList()
                      }}
                      data-cy={`tsm-table-${apiName}-select-${record.id}`}
                      data-testid={`tsm-table-${apiName}-select-${record.id}`}
                    >
                      <Option />
                      {companyList ? (
                        <Fragment>
                          {companyList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))}
                        </Fragment>
                      ) : (
                        <Option disabled>Loading options...</Option>
                      )}
                    </Select>
                  )}
                </>
              ) : (
                grayLabel(item, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'company_credit') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'company_credit'],
          key: 'company_credit',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Select
                        showSearch
                        defaultValue={item}
                        onChange={onChangePickListSetColumnValueVendorTaskDetails(
                          'company_credit',
                          'company_credit_id'
                        )}
                        optionFilterProp='children'
                        onClick={() => {
                          if (!companyList) getCompanyList()
                        }}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        {companyList ? (
                          companyList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))
                        ) : (
                          <Option disabled>Loading options...</Option>
                        )}
                      </Select>
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'company_credit',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'company_credit',
                              'company_credit_id',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Select
                      showSearch
                      defaultValue={item}
                      onSelect={onChangeSelector(
                        'company_credit',
                        record,
                        true,
                        'company_credit_id'
                      )}
                      optionFilterProp='children'
                      allowClear
                      onClear={onClearSelector(
                        'company_credit',
                        record,
                        true,
                        'company_credit_id'
                      )}
                      onClick={() => {
                        if (!companyList) getCompanyList()
                      }}
                      data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                      data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                    >
                      <Option />
                      {companyList ? (
                        <Fragment>
                          {companyList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))}
                        </Fragment>
                      ) : (
                        <Option disabled>Loading options...</Option>
                      )}
                    </Select>
                  )}
                </>
              ) : (
                grayLabel(item, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'intercompany_debit') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'intercompany_debit'],
          key: 'intercompany_debit',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Select
                        showSearch
                        defaultValue={item}
                        onChange={onChangePickListSetColumnValueVendorTaskDetails(
                          'intercompany_debit',
                          'intercompany_debit_id'
                        )}
                        optionFilterProp='children'
                        onClick={() => {
                          if (!interCompanyList) getInterCompanyList()
                        }}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        {interCompanyList ? (
                          interCompanyList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))
                        ) : (
                          <Option disabled>Loading options...</Option>
                        )}
                      </Select>
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'intercompany_debit',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'intercompany_debit',
                              'intercompany_debit_id',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Select
                      showSearch
                      defaultValue={item}
                      onSelect={onChangeSelector(
                        'intercompany_debit',
                        record,
                        true,
                        'intercompany_debit_id'
                      )}
                      optionFilterProp='children'
                      allowClear
                      onClear={onClearSelector(
                        'intercompany_debit',
                        record,
                        true,
                        'intercompany_debit_id'
                      )}
                      onClick={() => {
                        if (!interCompanyList) getInterCompanyList()
                      }}
                      data-cy={`tsm-table-${apiName}-select-${record.id}`}
                      data-testid={`tsm-table-${apiName}-select-${record.id}`}
                    >
                      {interCompanyList ? (
                        <Fragment>
                          <Option />
                          {interCompanyList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))}
                        </Fragment>
                      ) : (
                        <Option disabled>Loading options...</Option>
                      )}
                    </Select>
                  )}
                </>
              ) : (
                grayLabel(item, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'intercompany_credit') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'intercompany_credit'],
          key: 'intercompany_credit',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Select
                        showSearch
                        defaultValue={item}
                        onChange={onChangePickListSetColumnValueVendorTaskDetails(
                          'intercompany_credit',
                          'intercompany_credit_id'
                        )}
                        optionFilterProp='children'
                        onClick={() => {
                          if (!interCompanyList) getInterCompanyList()
                        }}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        {interCompanyList ? (
                          interCompanyList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))
                        ) : (
                          <Option disabled>Loading options...</Option>
                        )}
                      </Select>
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'intercompany_credit',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'intercompany_credit',
                              'intercompany_credit_id',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Select
                      showSearch
                      defaultValue={item}
                      onSelect={onChangeSelector(
                        'intercompany_credit',
                        record,
                        true,
                        'intercompany_credit_id'
                      )}
                      optionFilterProp='children'
                      allowClear
                      onClear={onClearSelector(
                        'intercompany_credit',
                        record,
                        true,
                        'intercompany_credit_id'
                      )}
                      onClick={() => {
                        if (!interCompanyList) getInterCompanyList()
                      }}
                      data-cy={`tsm-table-${apiName}-select-${record.id}`}
                      data-testid={`tsm-table-${apiName}-select-${record.id}`}
                    >
                      {interCompanyList ? (
                        <Fragment>
                          <Option />
                          {interCompanyList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))}
                        </Fragment>
                      ) : (
                        <Option disabled>Loading options...</Option>
                      )}
                    </Select>
                  )}
                </>
              ) : (
                grayLabel(item, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'channel_debit') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'channel_debit'],
          key: 'channel_debit',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Select
                        showSearch
                        defaultValue={item}
                        onChange={onChangePickListSetColumnValueVendorTaskDetails(
                          'channel_debit',
                          'channel_debit_id'
                        )}
                        optionFilterProp='children'
                        onClick={() => {
                          if (!channelList) getChannelList()
                        }}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        {channelList ? (
                          channelList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))
                        ) : (
                          <Option disabled>Loading options...</Option>
                        )}
                      </Select>
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'channel_debit',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'channel_debit',
                              'channel_debit_id',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Select
                      showSearch
                      defaultValue={item}
                      onSelect={onChangeSelector(
                        'channel_debit',
                        record,
                        true,
                        'channel_debit_id'
                      )}
                      optionFilterProp='children'
                      allowClear
                      onClear={onClearSelector(
                        'channel_debit',
                        record,
                        true,
                        'channel_debit_id'
                      )}
                      onClick={() => {
                        if (!channelList) getChannelList()
                      }}
                      data-cy={`tsm-table-${apiName}-select-${record.id}`}
                      data-testid={`tsm-table-${apiName}-select-${record.id}`}
                    >
                      {channelList ? (
                        <Fragment>
                          <Option />
                          {channelList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))}
                        </Fragment>
                      ) : (
                        <Option disabled>Loading options...</Option>
                      )}
                    </Select>
                  )}
                </>
              ) : (
                grayLabel(item, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'channel_credit') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'channel_credit'],
          key: 'channel_credit',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Select
                        showSearch
                        defaultValue={item}
                        onChange={onChangePickListSetColumnValueVendorTaskDetails(
                          'channel_credit',
                          'channel_credit_id'
                        )}
                        optionFilterProp='children'
                        onClick={() => {
                          if (!channelList) getChannelList()
                        }}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        {channelList ? (
                          channelList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))
                        ) : (
                          <Option disabled>Loading options...</Option>
                        )}
                      </Select>
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'channel_credit',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'channel_credit',
                              'channel_credit_id',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Select
                      showSearch
                      defaultValue={item}
                      onSelect={onChangeSelector(
                        'channel_credit',
                        record,
                        true,
                        'channel_credit_id'
                      )}
                      optionFilterProp='children'
                      allowClear
                      onClear={onClearSelector(
                        'channel_credit',
                        record,
                        true,
                        'channel_credit_id'
                      )}
                      onClick={() => {
                        if (!channelList) getChannelList()
                      }}
                      data-cy={`tsm-table-${apiName}-select-${record.id}`}
                      data-testid={`tsm-table-${apiName}-select-${record.id}`}
                    >
                      {channelList ? (
                        <Fragment>
                          <Option />
                          {channelList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))}
                        </Fragment>
                      ) : (
                        <Option disabled>Loading options...</Option>
                      )}
                    </Select>
                  )}
                </>
              ) : (
                grayLabel(item, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'location_debit') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'location_debit'],
          key: 'location_debit',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Select
                        showSearch
                        defaultValue={item}
                        onChange={onChangePickListSetColumnValueVendorTaskDetails(
                          'location_debit',
                          'location_debit_id'
                        )}
                        optionFilterProp='children'
                        onClick={() => {
                          if (!locationList) getLocationList()
                        }}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        {locationList ? (
                          locationList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))
                        ) : (
                          <Option disabled>Loading options...</Option>
                        )}
                      </Select>
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'location_debit',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'location_debit',
                              'location_debit_id',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Select
                      showSearch
                      defaultValue={item}
                      onSelect={onChangeSelector(
                        'location_debit',
                        record,
                        true,
                        'location_debit_id'
                      )}
                      optionFilterProp='children'
                      allowClear
                      onClear={onClearSelector(
                        'location_debit',
                        record,
                        true,
                        'location_debit_id'
                      )}
                      onClick={() => {
                        if (!locationList) getLocationList()
                      }}
                      data-cy={`tsm-table-${apiName}-select-${record.id}`}
                      data-testid={`tsm-table-${apiName}-select-${record.id}`}
                    >
                      {locationList ? (
                        <Fragment>
                          <Option />
                          {locationList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))}
                        </Fragment>
                      ) : (
                        <Option disabled>Loading options...</Option>
                      )}
                    </Select>
                  )}
                </>
              ) : (
                grayLabel(item, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'location_credit') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'location_credit'],
          key: 'location_credit',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Select
                        showSearch
                        defaultValue={item}
                        onChange={onChangePickListSetColumnValueVendorTaskDetails(
                          'location_credit',
                          'location_credit_id'
                        )}
                        optionFilterProp='children'
                        onClick={() => {
                          if (!locationList) getLocationList()
                        }}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        {locationList ? (
                          locationList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))
                        ) : (
                          <Option disabled>Loading options...</Option>
                        )}
                      </Select>
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'location_credit',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'location_credit',
                              'location_credit_id',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Select
                      showSearch
                      defaultValue={item}
                      onSelect={onChangeSelector(
                        'location_credit',
                        record,
                        true,
                        'location_credit_id'
                      )}
                      optionFilterProp='children'
                      allowClear
                      onClear={onClearSelector(
                        'location_credit',
                        record,
                        true,
                        'location_credit_id'
                      )}
                      onClick={() => {
                        if (!locationList) getLocationList()
                      }}
                      data-cy={`tsm-table-${apiName}-select-${record.id}`}
                      data-testid={`tsm-table-${apiName}-select-${record.id}`}
                    >
                      {locationList ? (
                        <Fragment>
                          <Option />
                          {locationList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))}
                        </Fragment>
                      ) : (
                        <Option disabled>Loading options...</Option>
                      )}
                    </Select>
                  )}
                </>
              ) : (
                grayLabel(item, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'class_debit') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'class_debit'],
          key: 'class_debit',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Select
                        showSearch
                        defaultValue={item}
                        onChange={onChangePickListSetColumnValueVendorTaskDetails(
                          'class_debit',
                          'class_debit_id'
                        )}
                        optionFilterProp='children'
                        onClick={() => {
                          if (!classList) getClassList()
                        }}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        {classList ? (
                          classList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))
                        ) : (
                          <Option disabled>Loading options...</Option>
                        )}
                      </Select>
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'class_debit',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button size='small' onClick={onClickCancel} ghost>
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'class_debit',
                              'class_debit_id',
                              record
                            )}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Select
                      showSearch
                      defaultValue={item}
                      onSelect={onChangeSelector(
                        'class_debit',
                        record,
                        true,
                        'class_debit_id'
                      )}
                      optionFilterProp='children'
                      allowClear
                      onClear={onClearSelector(
                        'class_debit',
                        record,
                        true,
                        'class_debit_id'
                      )}
                      onClick={() => {
                        if (!classList) getClassList()
                      }}
                      data-cy={`tsm-table-${apiName}-select-${record.id}`}
                      data-testid={`tsm-table-${apiName}-select-${record.id}`}
                    >
                      {classList ? (
                        <Fragment>
                          <Option />
                          {classList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))}
                        </Fragment>
                      ) : (
                        <Option disabled>Loading options...</Option>
                      )}
                    </Select>
                  )}
                </>
              ) : (
                grayLabel(item, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'class_credit') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'class_credit'],
          key: 'class_credit',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Select
                        showSearch
                        defaultValue={item}
                        onChange={onChangePickListSetColumnValueVendorTaskDetails(
                          'class_credit',
                          'class_credit_id'
                        )}
                        optionFilterProp='children'
                        onClick={() => {
                          if (!classList) getClassList()
                        }}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        {classList ? (
                          classList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))
                        ) : (
                          <Option disabled>Loading options...</Option>
                        )}
                      </Select>
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'class_credit',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'class_credit',
                              'class_credit_id',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Select
                      showSearch
                      defaultValue={item}
                      onSelect={onChangeSelector(
                        'class_credit',
                        record,
                        true,
                        'class_credit_id'
                      )}
                      optionFilterProp='children'
                      allowClear
                      onClear={onClearSelector(
                        'class_credit',
                        record,
                        true,
                        'class_credit_id'
                      )}
                      onClick={() => {
                        if (!classList) getClassList()
                      }}
                      data-cy={`tsm-table-${apiName}-select-${record.id}`}
                      data-testid={`tsm-table-${apiName}-select-${record.id}`}
                    >
                      {classList ? (
                        <Fragment>
                          <Option />
                          {classList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))}
                        </Fragment>
                      ) : (
                        <Option disabled>Loading options...</Option>
                      )}
                    </Select>
                  )}
                </>
              ) : (
                grayLabel(item, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'product_debit') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'product_debit'],
          key: 'product_debit',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Select
                        showSearch
                        defaultValue={item}
                        onChange={onChangePickListSetColumnValueVendorTaskDetails(
                          'product_debit',
                          'product_debit_id'
                        )}
                        optionFilterProp='children'
                        onClick={() => {
                          if (!productList) getProductList()
                        }}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        {productList ? (
                          productList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))
                        ) : (
                          <Option disabled>Loading options...</Option>
                        )}
                      </Select>
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'product_debit',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'product_debit',
                              'product_debit_id',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Select
                      showSearch
                      defaultValue={item}
                      onSelect={onChangeSelector(
                        'product_debit',
                        record,
                        true,
                        'product_debit_id'
                      )}
                      optionFilterProp='children'
                      allowClear
                      onClear={onClearSelector(
                        'product_debit',
                        record,
                        true,
                        'product_debit_id'
                      )}
                      onClick={() => {
                        if (!productList) getProductList()
                      }}
                      data-cy={`tsm-table-${apiName}-select-${record.id}`}
                      data-testid={`tsm-table-${apiName}-select-${record.id}`}
                    >
                      {productList ? (
                        <Fragment>
                          <Option />
                          {productList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))}
                        </Fragment>
                      ) : (
                        <Option disabled>Loading options...</Option>
                      )}
                    </Select>
                  )}
                </>
              ) : (
                grayLabel(item, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'product_credit') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'product_credit'],
          key: 'product_credit',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Select
                        showSearch
                        defaultValue={item}
                        onChange={onChangePickListSetColumnValueVendorTaskDetails(
                          'product_credit',
                          'product_credit_id'
                        )}
                        optionFilterProp='children'
                        onClick={() => {
                          if (!productList) getProductList()
                        }}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        {productList ? (
                          productList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))
                        ) : (
                          <Option disabled>Loading options...</Option>
                        )}
                      </Select>
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'product_credit',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'product_credit',
                              'product_credit_id',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Select
                      showSearch
                      defaultValue={item}
                      onSelect={onChangeSelector(
                        'product_credit',
                        record,
                        true,
                        'product_credit_id'
                      )}
                      optionFilterProp='children'
                      allowClear
                      onClear={onClearSelector(
                        'product_credit',
                        record,
                        true,
                        'product_credit_id'
                      )}
                      onClick={() => {
                        if (!productList) getProductList()
                      }}
                      data-cy={`tsm-table-${apiName}-select-${record.id}`}
                      data-testid={`tsm-table-${apiName}-select-${record.id}`}
                    >
                      {productList ? (
                        <Fragment>
                          <Option />
                          {productList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))}
                        </Fragment>
                      ) : (
                        <Option disabled>Loading options...</Option>
                      )}
                    </Select>
                  )}
                </>
              ) : (
                grayLabel(item, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'project_debit') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'project_debit'],
          key: 'project_debit',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Select
                        showSearch
                        defaultValue={item}
                        onChange={onChangePickListSetColumnValueVendorTaskDetails(
                          'project_debit',
                          'project_debit_id'
                        )}
                        optionFilterProp='children'
                        onClick={() => {
                          if (!projectList) getProjectList()
                        }}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        {projectList ? (
                          projectList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))
                        ) : (
                          <Option disabled>Loading options...</Option>
                        )}
                      </Select>
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'project_debit',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'project_debit',
                              'project_debit_id',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Select
                      showSearch
                      defaultValue={item}
                      onSelect={onChangeSelector(
                        'project_debit',
                        record,
                        true,
                        'project_debit_id'
                      )}
                      optionFilterProp='children'
                      allowClear
                      onClear={onClearSelector(
                        'project_debit',
                        record,
                        true,
                        'project_debit_id'
                      )}
                      onClick={() => {
                        if (!projectList) getProjectList()
                      }}
                      data-cy={`tsm-table-${apiName}-select-${record.id}`}
                      data-testid={`tsm-table-${apiName}-select-${record.id}`}
                    >
                      {projectList ? (
                        <Fragment>
                          <Option />
                          {projectList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))}
                        </Fragment>
                      ) : (
                        <Option disabled>Loading options...</Option>
                      )}
                    </Select>
                  )}
                </>
              ) : (
                grayLabel(item, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'project_credit') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'project_credit'],
          key: 'project_credit',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Select
                        showSearch
                        defaultValue={item}
                        onChange={onChangePickListSetColumnValueVendorTaskDetails(
                          'project_credit',
                          'project_credit_id'
                        )}
                        optionFilterProp='children'
                        onClick={() => {
                          if (!projectList) getProjectList()
                        }}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        {projectList ? (
                          projectList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))
                        ) : (
                          <Option disabled>Loading options...</Option>
                        )}
                      </Select>
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'project_credit',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'project_credit',
                              'project_credit_id',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Select
                      showSearch
                      defaultValue={item}
                      onSelect={onChangeSelector(
                        'project_credit',
                        record,
                        true,
                        'project_credit_id'
                      )}
                      optionFilterProp='children'
                      allowClear
                      onClear={onClearSelector(
                        'project_credit',
                        record,
                        true,
                        'project_credit_id'
                      )}
                      onClick={() => {
                        if (!projectList) getProjectList()
                      }}
                      data-cy={`tsm-table-${apiName}-select-${record.id}`}
                      data-testid={`tsm-table-${apiName}-select-${record.id}`}
                    >
                      {projectList ? (
                        <Fragment>
                          <Option />
                          {projectList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))}
                        </Fragment>
                      ) : (
                        <Option disabled>Loading options...</Option>
                      )}
                    </Select>
                  )}
                </>
              ) : (
                grayLabel(item, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'region_debit') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'region_debit'],
          key: 'region_debit',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Select
                        showSearch
                        defaultValue={item}
                        onChange={onChangePickListSetColumnValueVendorTaskDetails(
                          'region_debit',
                          'region_debit_id'
                        )}
                        optionFilterProp='children'
                        onClick={() => {
                          if (!regionList) getRegionList()
                        }}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        {regionList ? (
                          regionList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))
                        ) : (
                          <Option disabled>Loading options...</Option>
                        )}
                      </Select>
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'region_debit',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'region_debit',
                              'region_debit_id',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Select
                      showSearch
                      defaultValue={item}
                      onSelect={onChangeSelector(
                        'region_debit',
                        record,
                        true,
                        'region_debit_id'
                      )}
                      optionFilterProp='children'
                      allowClear
                      onClear={onClearSelector(
                        'region_debit',
                        record,
                        true,
                        'region_debit_id'
                      )}
                      onClick={() => {
                        if (!regionList) getRegionList()
                      }}
                      data-cy={`tsm-table-${apiName}-select-${record.id}`}
                      data-testid={`tsm-table-${apiName}-select-${record.id}`}
                    >
                      {regionList ? (
                        <Fragment>
                          <Option />
                          {regionList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))}
                        </Fragment>
                      ) : (
                        <Option disabled>Loading options...</Option>
                      )}
                    </Select>
                  )}
                </>
              ) : (
                grayLabel(item, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'region_credit') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'region_credit'],
          key: 'region_credit',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Select
                        showSearch
                        defaultValue={item}
                        onChange={onChangePickListSetColumnValueVendorTaskDetails(
                          'region_credit',
                          'region_credit_id'
                        )}
                        optionFilterProp='children'
                        onClick={() => {
                          if (!regionList) getRegionList()
                        }}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        {regionList ? (
                          regionList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))
                        ) : (
                          <Option disabled>Loading options...</Option>
                        )}
                      </Select>
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'region_credit',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'region_credit',
                              'region_credit_id',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Select
                      showSearch
                      defaultValue={item}
                      onSelect={onChangeSelector(
                        'region_credit',
                        record,
                        true,
                        'region_credit_id'
                      )}
                      optionFilterProp='children'
                      allowClear
                      onClear={onClearSelector(
                        'region_credit',
                        record,
                        true,
                        'region_credit_id'
                      )}
                      onClick={() => {
                        if (!regionList) getRegionList()
                      }}
                      data-cy={`tsm-table-${apiName}-select-${record.id}`}
                      data-testid={`tsm-table-${apiName}-select-${record.id}`}
                    >
                      {regionList ? (
                        <Fragment>
                          <Option />
                          {regionList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))}
                        </Fragment>
                      ) : (
                        <Option disabled>Loading options...</Option>
                      )}
                    </Select>
                  )}
                </>
              ) : (
                grayLabel(item, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'transaction_currency') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'transaction_currency'],
          key: 'transaction_currency',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id &&
              isEditable(record) &&
              record.task_inactive === 0 ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Select
                        showSearch
                        defaultValue={item}
                        onChange={onChangePickListSetColumnValueVendorTaskDetails(
                          'transaction_currency',
                          'transaction_currency_id'
                        )}
                        optionFilterProp='children'
                        onClick={() => {
                          if (!currencyList) getCurrencyList()
                        }}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        {currencyList ? (
                          currencyList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))
                        ) : (
                          <Option disabled>Loading options...</Option>
                        )}
                      </Select>
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'transaction_currency',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'transaction_currency',
                              'transaction_currency_id',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Select
                      showSearch
                      defaultValue={item}
                      onSelect={onChangeSelector(
                        'transaction_currency',
                        record,
                        true,
                        'transaction_currency_id'
                      )}
                      optionFilterProp='children'
                      allowClear
                      onClear={onClearSelector(
                        'transaction_currency',
                        record,
                        true,
                        'transaction_currency_id'
                      )}
                      onClick={() => {
                        if (!currencyList) getCurrencyList()
                      }}
                      data-cy={`tsm-table-${apiName}-select-${record.id}`}
                      data-testid={`tsm-table-${apiName}-select-${record.id}`}
                    >
                      {currencyList ? (
                        <Fragment>
                          <Option />
                          {currencyList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))}
                        </Fragment>
                      ) : (
                        <Option disabled>Loading options...</Option>
                      )}
                    </Select>
                  )}
                </>
              ) : (
                grayLabel(item, !isEditable(record))
              )}
            </Fragment>
          )
        })
      } else if (
        item.api_name == 'po_owner' ||
        item.api_name == 'po_header_description'
      ) {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', item.api_name],
          key: item.api_name,
          width: displayTextWidth(item.label),
          render: (value, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Input
                        defaultValue={value}
                        onChange={onChangeInputFieldColumnValueVendorTaskDetails(
                          item.api_name
                        )}
                        data-cy={`tsm-table-${apiName}-input-${record.id}`}
                        data-testid={`tsm-table-${apiName}-input-${record.id}`}
                      />
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            item.api_name,
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              item.api_name,
                              '',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Input
                      defaultValue={value}
                      onPressEnter={onPressEnterField(
                        item.api_name,
                        record,
                        true
                      )}
                      data-cy={`tsm-table-${apiName}-input-${record.id}`}
                      data-testid={`tsm-table-${apiName}-input-${record.id}`}
                    ></Input>
                  )}
                </>
              ) : (
                grayLabel(value, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'po_header_amount') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', item.api_name],
          key: item.api_name,
          width: displayTextWidth(item.label),
          render: (value, record) => (
            <Fragment>
              {editKey === record.id && isPOEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Input
                        defaultValue={value}
                        onChange={onChangeInputFieldColumnValueVendorTaskDetails(
                          item.api_name
                        )}
                        data-cy={`tsm-table-${apiName}-input-${record.id}`}
                        data-testid={`tsm-table-${apiName}-input-${record.id}`}
                      />
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            item.api_name,
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              item.api_name,
                              '',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Input
                      defaultValue={value}
                      onPressEnter={onPressEnterField(
                        item.api_name,
                        record,
                        true
                      )}
                      data-cy={`tsm-table-${apiName}-input-${record.id}`}
                      data-testid={`tsm-table-${apiName}-input-${record.id}`}
                    ></Input>
                  )}
                </>
              ) : (
                grayLabel(value, !isPOEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'po_line_id') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', item.api_name],
          key: item.api_name,
          width: displayTextWidth(item.label),
          render: (value, record) => (
            <Fragment>
              {editKey === record.id && isPOEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Input
                        defaultValue={value}
                        onChange={onChangeInputFieldColumnValueVendorTaskDetails(
                          item.api_name
                        )}
                        data-cy={`tsm-table-${apiName}-input-${record.id}`}
                        data-testid={`tsm-table-${apiName}-input-${record.id}`}
                      />
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            item.api_name,
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              item.api_name,
                              '',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Input
                      defaultValue={value}
                      onPressEnter={onPressEnterField(
                        item.api_name,
                        record,
                        true
                      )}
                      data-cy={`tsm-table-${apiName}-input-${record.id}`}
                      data-testid={`tsm-table-${apiName}-input-${record.id}`}
                    ></Input>
                  )}
                </>
              ) : (
                grayLabel(value, !isPOEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'po_id') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', item.api_name],
          key: item.api_name,
          width: displayTextWidth(item.label),
          render: (value, record) => (
            <Fragment>
              {editKey === record.id && isPOEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Input
                        defaultValue={value}
                        onChange={onChangeInputFieldColumnValueVendorTaskDetails(
                          item.api_name
                        )}
                        data-cy={`tsm-table-${apiName}-input-${record.id}`}
                        data-testid={`tsm-table-${apiName}-input-${record.id}`}
                      />
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            item.api_name,
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              item.api_name,
                              '',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Input
                      defaultValue={value}
                      onPressEnter={onPressEnterField(
                        item.api_name,
                        record,
                        true
                      )}
                      data-cy={`tsm-table-${apiName}-input-${record.id}`}
                      data-testid={`tsm-table-${apiName}-input-${record.id}`}
                    ></Input>
                  )}
                </>
              ) : (
                grayLabel(value, !isPOEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'po_line_status') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'po_line_status'],
          key: 'po_line_status',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id &&
              isPOEditableNewRule(record) &&
              record.task_inactive === 0 ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Input
                        defaultValue={item}
                        onChange={onChangeInputFieldColumnValueVendorTaskDetails(
                          'po_line_status'
                        )}
                        data-cy={`tsm-table-${apiName}-input-${record.id}`}
                        data-testid={`tsm-table-${apiName}-input-${record.id}`}
                      />
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'po_line_status',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'po_line_status',
                              '',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Input
                      defaultValue={item}
                      onPressEnter={onPressEnterField(
                        'po_line_status',
                        record,
                        true
                      )}
                      data-cy={`tsm-table-${apiName}-input-${record.id}`}
                      data-testid={`tsm-table-${apiName}-input-${record.id}`}
                    ></Input>
                  )}
                </>
              ) : (
                grayLabel(item, !isPOEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'service_start_date') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'service_start_date'],
          key: 'service_start_date',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id &&
              isPOEditableNewRule(record) &&
              record.task_inactive === 0 ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <DatePicker
                        defaultValue={item && moment(item)}
                        onChange={onChangeDatePickerColumnValueVendorTaskDetails(
                          'service_start_date'
                        )}
                        data-cy={`tsm-table-${apiName}-datepicker-${record.id}`}
                        data-testid={`tsm-table-${apiName}-datepicker-${record.id}`}
                      />
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'service_start_date',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'service_start_date',
                              '',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <div>
                      <DatePicker
                        defaultValue={item && moment(item)}
                        onChange={onChangeDatePicker(
                          'service_start_date',
                          record
                        )}
                        data-cy={`tsm-table-${apiName}-datepicker-${record.id}`}
                        data-testid={`tsm-table-${apiName}-datepicker-${record.id}`}
                      />
                    </div>
                  )}
                </>
              ) : (
                grayLabel(
                  item && moment(item).format('YYYY-MM-DD'),
                  !isPOEditableNewRule(record)
                )
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'service_end_date') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'service_end_date'],
          key: 'service_end_date',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id &&
              isPOEditableNewRule(record) &&
              record.task_inactive === 0 ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <DatePicker
                        defaultValue={item && moment(item)}
                        onChange={onChangeDatePickerColumnValueVendorTaskDetails(
                          'service_end_date'
                        )}
                        data-cy={`tsm-table-${apiName}-datepicker-${record.id}`}
                        data-testid={`tsm-table-${apiName}-datepicker-${record.id}`}
                      />
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'service_end_date',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'service_end_date',
                              '',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <div>
                      <DatePicker
                        defaultValue={item && moment(item)}
                        onChange={onChangeDatePicker(
                          'service_end_date',
                          record
                        )}
                        data-cy={`tsm-table-${apiName}-datepicker-${record.id}`}
                        data-testid={`tsm-table-${apiName}-datepicker-${record.id}`}
                      />
                    </div>
                  )}
                </>
              ) : (
                grayLabel(
                  item && moment(item).format('YYYY-MM-DD'),
                  !isPOEditableNewRule(record)
                )
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'po_line_amount') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'po_line_amount'],
          key: 'po_line_amount',
          align: 'right',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id &&
              isPOEditableNewRule(record) &&
              record.task_inactive === 0 ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Input
                        defaultValue={item}
                        onChange={onChangeInputFieldColumnValueVendorTaskDetails(
                          'po_line_amount'
                        )}
                        data-cy={`tsm-table-${apiName}-input-${record.id}`}
                        data-testid={`tsm-table-${apiName}-input-${record.id}`}
                      />
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'po_line_amount',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'po_line_amount',
                              '',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Input
                      defaultValue={item}
                      onPressEnter={onPressEnterField(
                        'po_line_amount',
                        record,
                        true
                      )}
                      data-cy={`tsm-table-${apiName}-input-${record.id}`}
                      data-testid={`tsm-table-${apiName}-input-${record.id}`}
                    ></Input>
                  )}
                </>
              ) : (
                grayLabel(formatIfDecimal(item), !isPOEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'invoiced_amount') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'invoiced_amount'],
          key: 'invoiced_amount',
          align: 'right',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id &&
              isPOEditableNewRule(record) &&
              record.task_inactive === 0 ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Input
                        defaultValue={item}
                        onChange={onChangeInputFieldColumnValueVendorTaskDetails(
                          'invoiced_amount'
                        )}
                        data-cy={`tsm-table-${apiName}-input-${record.id}`}
                        data-testid={`tsm-table-${apiName}-input-${record.id}`}
                      />
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'invoiced_amount',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'invoiced_amount',
                              '',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Input
                      defaultValue={item}
                      onPressEnter={onPressEnterField(
                        'invoiced_amount',
                        record,
                        true
                      )}
                      data-cy={`tsm-table-${apiName}-input-${record.id}`}
                      data-testid={`tsm-table-${apiName}-input-${record.id}`}
                    ></Input>
                  )}
                </>
              ) : (
                grayLabel(formatIfDecimal(item), !isPOEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'po_line_amount_cancelled') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'po_line_amount_cancelled'],
          key: 'po_line_amount_cancelled',
          align: 'right',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id &&
              isPOEditableNewRule(record) &&
              record.task_inactive === 0 ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Input
                        defaultValue={item}
                        onChange={onChangeInputFieldColumnValueVendorTaskDetails(
                          'po_line_amount_cancelled'
                        )}
                        data-cy={`tsm-table-${apiName}-input-${record.id}`}
                        data-testid={`tsm-table-${apiName}-input-${record.id}`}
                      />
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'po_line_amount_cancelled',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'po_line_amount_cancelled',
                              '',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Input
                      defaultValue={item}
                      onPressEnter={onPressEnterField(
                        'po_line_amount_cancelled',
                        record,
                        true
                      )}
                      data-cy={`tsm-table-${apiName}-input-${record.id}`}
                      data-testid={`tsm-table-${apiName}-input-${record.id}`}
                    ></Input>
                  )}
                </>
              ) : (
                grayLabel(formatIfDecimal(item), !isPOEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'po_balance') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'po_balance'],
          key: 'po_balance',
          align: 'right',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id &&
              isPOEditableNewRule(record) &&
              record.task_inactive === 0 ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Input
                        defaultValue={item}
                        onChange={onChangeInputFieldColumnValueVendorTaskDetails(
                          'po_balance'
                        )}
                        data-cy={`tsm-table-${apiName}-input-${record.id}`}
                        data-testid={`tsm-table-${apiName}-input-${record.id}`}
                      />
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'po_balance',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'po_balance',
                              '',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Input
                      defaultValue={item}
                      onPressEnter={onPressEnterField(
                        'po_balance',
                        record,
                        true
                      )}
                      data-cy={`tsm-table-${apiName}-input-${record.id}`}
                      data-testid={`tsm-table-${apiName}-input-${record.id}`}
                    ></Input>
                  )}
                </>
              ) : (
                grayLabel(formatIfDecimal(item), !isPOEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'task_type') {
        columns.push({
          title: item.label,
          dataIndex: 'task_type',
          key: 'task_type',
          width: displayTextWidth(item.label),
          render: (item) => (
            <Fragment>
              <TableTag
                color={VendorTaskTypeEnum.getColorFromKey(item)}
                background={VendorTaskTypeEnum.getBgColorFromKey(item)}
                name={item}
              />
            </Fragment>
          )
        })
      } else if (item.api_name == 'tx_name') {
        columns.push({
          title: item.label,
          dataIndex: 'tx_name',
          key: 'tx_name',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {record.task_inactive === 0 ? (
                editKey === record.id ? (
                  <>
                    {selectedRows.length > 1 && isRowSelected(record) ? (
                      <div className='bulk-column'>
                        <Input
                          defaultValue={item}
                          onChange={onChangeInputField('tx_name')}
                          data-cy={`tsm-table-${apiName}-input-${record.id}`}
                          data-testid={`tsm-table-${apiName}-input-${record.id}`}
                        />
                        <br />
                        <div className='checkbox-lbl'>
                          <Checkbox
                            defaultChecked={false}
                            onChange={onChangeCheckboxApplyToSelectedRow(
                              'tx_name',
                              record.id
                            )}
                            data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                            data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                          />
                          <label>
                            Update {selectedRows.length} selected items
                          </label>
                        </div>
                        <Fragment>
                          <div className='bulk-buttons'>
                            <Button
                              size='small'
                              onClick={onClickCancel}
                              data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                              data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                              ghost
                            >
                              Cancel
                            </Button>
                            <Button
                              type='primary'
                              size='small'
                              onClick={onClickApplyChangesToSelectedRows(
                                'tx_name',
                                record,
                                item
                              )}
                              data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                              data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            >
                              Apply
                            </Button>
                          </div>
                        </Fragment>
                      </div>
                    ) : (
                      <Input
                        defaultValue={item}
                        // onChange={onChangeField("g_transaction_group", record)}
                        onPressEnter={onPressEnterField('tx_name', record)}
                        data-cy={`tsm-table-${apiName}-input-${record.id}`}
                        data-testid={`tsm-table-${apiName}-input-${record.id}`}
                      />
                    )}
                  </>
                ) : (
                  item
                )
              ) : (
                grayLabel(item, !isEditable(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'calculated_accrual_form') {
        columns.push({
          title: item.label,
          dataIndex: 'calculated_accrual_form',
          key: 'calculated_accrual_form',
          width: displayTextWidth(item.label),
          render: (data, record) => (
            <Fragment>
              {record.task_inactive === 0 ? (
                editKey === record.id ? (
                  <>
                    {selectedRows.length > 1 && isRowSelected(record) ? (
                      <div className='bulk-column'>
                        <Select
                          showSearch
                          defaultValue={data}
                          onChange={onChangePickListSetColumnValue(
                            'calculated_accrual_form'
                          )}
                          onClick={() => {
                            if (!formList) getFormList()
                          }}
                          data-cy={`tsm-table-${apiName}-select-${record.id}`}
                          data-testid={`tsm-table-${apiName}-select-${record.id}`}
                        >
                          {formList ? (
                            <Fragment>
                              {formList.map((item) => {
                                let option
                                if (item.app.app_key == 'Calculated') {
                                  option = (
                                    <Option key={item.id} value={item.value}>
                                      {item.value}
                                    </Option>
                                  )
                                }
                                return option
                              })}
                            </Fragment>
                          ) : (
                            <Option disabled>Loading options...</Option>
                          )}
                        </Select>
                        <br />
                        <div className='checkbox-lbl'>
                          <Checkbox
                            defaultChecked={false}
                            onChange={onChangeCheckboxApplyToSelectedRow(
                              'calculated_accrual_form',
                              record.id
                            )}
                            data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                            data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                          />
                          <label>
                            Update {selectedRows.length} selected items
                          </label>
                        </div>
                        <Fragment>
                          <div className='bulk-buttons'>
                            <Button
                              size='small'
                              onClick={onClickCancel}
                              data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                              data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                              ghost
                            >
                              Cancel
                            </Button>
                            <Button
                              type='primary'
                              size='small'
                              onClick={onClickApplyChangesToSelectedRows(
                                'calculated_accrual_form',
                                record,
                                data
                              )}
                              data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                              data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            >
                              Apply
                            </Button>
                          </div>
                        </Fragment>
                      </div>
                    ) : (
                      <Select
                        showSearch
                        defaultValue={data}
                        onSelect={onChangeSelector(
                          'calculated_accrual_form',
                          record
                        )}
                        allowClear
                        onClear={onClearSelector(
                          'calculated_accrual_form',
                          record
                        )}
                        onClick={() => {
                          if (!formList) getFormList()
                        }}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        {formList ? (
                          <Fragment>
                            <Option />
                            {formList.map((item) => {
                              let option
                              if (item.app.app_key == 'Calculated') {
                                option = (
                                  <Option key={item.id} value={item.value}>
                                    {item.value}
                                  </Option>
                                )
                              }
                              return option
                            })}
                          </Fragment>
                        ) : (
                          <Option disabled>Loading options...</Option>
                        )}
                      </Select>
                    )}
                  </>
                ) : (
                  data
                )
              ) : (
                data &&
                grayLabel(
                  data,
                  !(isEditable(record) && !record.internal_source_vendor_id)
                )
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'external_accrual_form') {
        columns.push({
          title: item.label,
          dataIndex: 'external_accrual_form',
          key: 'external_accrual_form',
          width: displayTextWidth(item.label),
          render: (data, record) => (
            <Fragment>
              {record.task_inactive === 0 ? (
                editKey === record.id ? (
                  <>
                    {selectedRows.length > 1 && isRowSelected(record) ? (
                      <div className='bulk-column'>
                        <Select
                          showSearch
                          defaultValue={data}
                          onChange={onChangePickListSetColumnValue(
                            'external_accrual_form'
                          )}
                          data-cy={`tsm-table-${apiName}-select-${record.id}`}
                          data-testid={`tsm-table-${apiName}-select-${record.id}`}
                        >
                          {formList && (
                            <Fragment>
                              {formList.map((item) => {
                                let option
                                if (item.app.app_key == 'External') {
                                  option = (
                                    <Option key={item.id} value={item.value}>
                                      {item.value}
                                    </Option>
                                  )
                                }
                                return option
                              })}
                            </Fragment>
                          )}
                        </Select>
                        <br />
                        <div className='checkbox-lbl'>
                          <Checkbox
                            defaultChecked={false}
                            onChange={onChangeCheckboxApplyToSelectedRow(
                              'external_accrual_form',
                              record.id
                            )}
                            data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                            data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                          />
                          <label>
                            Update {selectedRows.length} selected items
                          </label>
                        </div>
                        <Fragment>
                          <div className='bulk-buttons'>
                            <Button
                              size='small'
                              onClick={onClickCancel}
                              data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                              data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                              ghost
                            >
                              Cancel
                            </Button>
                            <Button
                              type='primary'
                              size='small'
                              onClick={onClickApplyChangesToSelectedRows(
                                'external_accrual_form',
                                record,
                                data
                              )}
                              data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                              data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            >
                              Apply
                            </Button>
                          </div>
                        </Fragment>
                      </div>
                    ) : (
                      <Select
                        showSearch
                        defaultValue={data}
                        onSelect={onChangeSelector(
                          'external_accrual_form',
                          record
                        )}
                        allowClear
                        onClear={onClearSelector(
                          'external_accrual_form',
                          record
                        )}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        {formList && (
                          <Fragment>
                            <Option />
                            {formList.map((item) => {
                              let option
                              if (item.app.app_key == 'External') {
                                option = (
                                  <Option key={item.id} value={item.value}>
                                    {item.value}
                                  </Option>
                                )
                              }
                              return option
                            })}
                          </Fragment>
                        )}
                      </Select>
                    )}
                  </>
                ) : (
                  data
                )
              ) : (
                data &&
                grayLabel(
                  data,
                  !(isEditable(record) && !record.internal_source_vendor_id)
                )
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'internal_accrual_form') {
        columns.push({
          title: item.label,
          dataIndex: item.api_name,
          key: item.api_name,
          width: displayTextWidth(item.label),
          render: (data, record) => (
            <Fragment>
              {record.task_inactive === 0 ? (
                editKey === record.id ? (
                  <>
                    {selectedRows.length > 1 && isRowSelected(record) ? (
                      <div className='bulk-column'>
                        <Select
                          showSearch
                          defaultValue={data}
                          onChange={onChangePickListSetColumnValue(
                            'internal_accrual_form'
                          )}
                          data-cy={`tsm-table-${apiName}-select-${record.id}`}
                          data-testid={`tsm-table-${apiName}-select-${record.id}`}
                        >
                          {formList && (
                            <Fragment>
                              {formList.map((item) => {
                                let option
                                if (item.app.app_key == 'Internal') {
                                  option = (
                                    <Option key={item.id} value={item.value}>
                                      {item.value}
                                    </Option>
                                  )
                                }
                                return option
                              })}
                            </Fragment>
                          )}
                        </Select>
                        <br />
                        <div className='checkbox-lbl'>
                          <Checkbox
                            defaultChecked={false}
                            onChange={onChangeCheckboxApplyToSelectedRow(
                              'internal_accrual_form',
                              record.id
                            )}
                            data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                            data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                          />
                          <label>
                            Update {selectedRows.length} selected items
                          </label>
                        </div>
                        <Fragment>
                          <div className='bulk-buttons'>
                            <Button
                              size='small'
                              onClick={onClickCancel}
                              data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                              data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                              ghost
                            >
                              Cancel
                            </Button>
                            <Button
                              type='primary'
                              size='small'
                              onClick={onClickApplyChangesToSelectedRows(
                                'internal_accrual_form',
                                record,
                                data
                              )}
                              data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                              data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            >
                              Apply
                            </Button>
                          </div>
                        </Fragment>
                      </div>
                    ) : (
                      <Select
                        showSearch
                        defaultValue={data}
                        onSelect={onChangeSelector(
                          'internal_accrual_form',
                          record
                        )}
                        allowClear
                        onClear={onClearSelector(
                          'internal_accrual_form',
                          record
                        )}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        {formList && (
                          <Fragment>
                            <Option />
                            {formList.map((item) => {
                              let option
                              if (item.app.app_key == 'Internal') {
                                option = (
                                  <Option key={item.id} value={item.value}>
                                    {item.value}
                                  </Option>
                                )
                              }
                              return option
                            })}
                          </Fragment>
                        )}
                      </Select>
                    )}
                  </>
                ) : (
                  data
                )
              ) : (
                data &&
                grayLabel(
                  data,
                  !(isEditable(record) && !record.internal_source_vendor_id)
                )
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'po_number') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', item.api_name],
          key: item.api_name,
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id &&
              isPOEditableNewRule(record) &&
              record.task_inactive === 0 ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Input
                        defaultValue={item}
                        onChange={onChangeInputFieldColumnValueVendorTaskDetails(
                          'po_number'
                        )}
                        data-cy={`tsm-table-${apiName}-input-${record.id}`}
                        data-testid={`tsm-table-${apiName}-input-${record.id}`}
                      />
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'po_number',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'po_number',
                              '',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Input
                      defaultValue={item}
                      onPressEnter={onPressEnterField(
                        'po_number',
                        record,
                        true
                      )}
                      data-cy={`tsm-table-${apiName}-input-${record.id}`}
                      data-testid={`tsm-table-${apiName}-input-${record.id}`}
                    ></Input>
                  )}
                </>
              ) : (
                item && grayLabel(item, !isPOEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'po_line_description') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', item.api_name],
          key: item.api_name,
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Input
                        defaultValue={item}
                        onChange={onChangeInputFieldColumnValueVendorTaskDetails(
                          'po_line_description'
                        )}
                        data-cy={`tsm-table-${apiName}-input-${record.id}`}
                        data-testid={`tsm-table-${apiName}-input-${record.id}`}
                      />
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'po_line_description',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'po_line_description',
                              '',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Input
                      defaultValue={item}
                      onPressEnter={onPressEnterField(
                        'po_line_description',
                        record,
                        true
                      )}
                      data-cy={`tsm-table-${apiName}-input-${record.id}`}
                      data-testid={`tsm-table-${apiName}-input-${record.id}`}
                    ></Input>
                  )}
                </>
              ) : (
                item && grayLabel(item, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'po_date') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', item.api_name],
          key: item.api_name,
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id &&
              isPOEditableNewRule(record) &&
              record.task_inactive === 0 ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Input
                        defaultValue={item}
                        onChange={onChangeInputFieldColumnValueVendorTaskDetails(
                          'po_line_description'
                        )}
                        data-cy={`tsm-table-${apiName}-input-${record.id}`}
                        data-testid={`tsm-table-${apiName}-input-${record.id}`}
                      />
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'po_line_description',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'po_line_description',
                              '',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Input
                      defaultValue={item}
                      onPressEnter={onPressEnterField(
                        'po_line_description',
                        record,
                        true
                      )}
                      data-cy={`tsm-table-${apiName}-input-${record.id}`}
                      data-testid={`tsm-table-${apiName}-input-${record.id}`}
                    ></Input>
                  )}
                </>
              ) : (
                item && grayLabel(item, !isPOEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'created_at') {
        columns.push({
          title: item.label,
          // dataIndex: 'created_at',
          dataIndex: ['vendor_task_details', 'created_at'],
          key: 'created_at',
          width: displayTextWidth(item.label),
          render: (item) => (
            <Fragment>
              <span>
                {grayLabel(
                  item &&
                    moment(item).tz(userTimezone).format(userDateTimeFormat),
                  true
                )}
              </span>
            </Fragment>
          )
        })
      } else if (item.api_name == 'updated_at') {
        columns.push({
          title: item.label,
          // dataIndex: 'updated_at',
          dataIndex: ['vendor_task_details', 'updated_at'],
          key: 'updated_at',
          width: displayTextWidth(item.label),
          render: (item) => (
            <Fragment>
              <span>
                {grayLabel(
                  item &&
                    moment(item).tz(userTimezone).format(userDateTimeFormat),
                  true
                )}
              </span>
            </Fragment>
          )
        })
      } else if (item.api_name == 'po_line_amount_usd') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'po_line_amount_usd'],
          key: 'po_line_amount_usd',
          align: 'right',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id &&
              isPOEditableNewRule(record) &&
              record.task_inactive === 0 ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <InputNumber
                        defaultValue={item}
                        onChange={onChangeInputFieldColumnValueVendorTaskDetails(
                          'po_line_amount_usd'
                        )}
                        data-cy={`tsm-table-${apiName}-input-${record.id}`}
                        data-testid={`tsm-table-${apiName}-input-${record.id}`}
                      />
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'po_line_amount_usd',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'po_line_amount_usd',
                              '',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <InputNumber
                      defaultValue={item}
                      onPressEnter={onPressEnterField(
                        'po_line_amount_usd',
                        record,
                        true
                      )}
                      data-cy={`tsm-table-${apiName}-input-${record.id}`}
                      data-testid={`tsm-table-${apiName}-input-${record.id}`}
                    ></InputNumber>
                  )}
                </>
              ) : (
                grayLabel(formatIfDecimal(item), !isPOEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'po_balance_usd') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'po_balance_usd'],
          key: 'po_balance_usd',
          align: 'right',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id &&
              isPOEditableNewRule(record) &&
              record.task_inactive === 0 ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <InputNumber
                        defaultValue={item}
                        onChange={onChangeInputFieldColumnValueVendorTaskDetails(
                          'po_balance_usd'
                        )}
                        data-cy={`tsm-table-${apiName}-input-${record.id}`}
                        data-testid={`tsm-table-${apiName}-input-${record.id}`}
                      />
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'po_balance_usd',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'po_balance_usd',
                              '',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <InputNumber
                      defaultValue={item}
                      onPressEnter={onPressEnterField(
                        'po_balance_usd',
                        record,
                        true
                      )}
                      data-cy={`tsm-table-${apiName}-input-${record.id}`}
                      data-testid={`tsm-table-${apiName}-input-${record.id}`}
                    ></InputNumber>
                  )}
                </>
              ) : (
                grayLabel(formatIfDecimal(item), !isPOEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name == 'g_prepaid_account') {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', 'prepaid_account'],
          key: 'prepaid_account',
          width: displayTextWidth(item.label),
          render: (item, record) => (
            <Fragment>
              {editKey === record.id && isEditableNewRule(record) ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Select
                        showSearch
                        defaultValue={item}
                        onChange={onChangePickListSetColumnValueVendorTaskDetails(
                          'prepaid_account',
                          'prepaid_account_id'
                        )}
                        optionFilterProp='children'
                        onClick={() => {
                          if (!glAccountList) getGlAccountList()
                        }}
                        data-cy={`tsm-table-${apiName}-select-${record.id}`}
                        data-testid={`tsm-table-${apiName}-select-${record.id}`}
                      >
                        {glAccountList ? (
                          glAccountList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))
                        ) : (
                          <Option disabled>Loading options...</Option>
                        )}
                      </Select>
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            'prepaid_account',
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              'prepaid_account',
                              'prepaid_account_id',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Select
                      showSearch
                      defaultValue={item}
                      onSelect={onChangeSelector(
                        'prepaid_account',
                        record,
                        true,
                        'prepaid_account_id'
                      )}
                      optionFilterProp='children'
                      allowClear
                      onClear={onClearSelector(
                        'prepaid_account',
                        record,
                        true,
                        'prepaid_account_id'
                      )}
                      onClick={() => {
                        if (!glAccountList) getGlAccountList()
                      }}
                      data-cy={`tsm-table-${apiName}-select-${record.id}`}
                      data-testid={`tsm-table-${apiName}-select-${record.id}`}
                    >
                      {glAccountList ? (
                        <Fragment>
                          <Option />
                          {glAccountList.map((item) => (
                            <Option
                              key={item.g_source_system_id}
                              value={item.g_source_system_id}
                            >
                              {item.value}
                            </Option>
                          ))}
                        </Fragment>
                      ) : (
                        <Option disabled>Loading options...</Option>
                      )}
                    </Select>
                  )}
                </>
              ) : (
                grayLabel(item, !isEditableNewRule(record))
              )}
            </Fragment>
          )
        })
      } else if (item.api_name.includes('custom_date')) {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', item.api_name],
          key: item.api_name,
          width: displayTextWidth(item.label),
          render: (value, record) => (
            <Fragment>
              {editKey === record.id && record.task_inactive === 0 ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <DatePicker
                        defaultValue={value && moment(value)}
                        onChange={onChangeDatePickerColumnValueVendorTaskDetails(
                          item.api_name
                        )}
                        data-cy={`tsm-table-${apiName}-datepicker-${record.id}`}
                        data-testid={`tsm-table-${apiName}-datepicker-${record.id}`}
                      />
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            item.api_name,
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              item.api_name,
                              '',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <div>
                      <DatePicker
                        defaultValue={value && moment(value)}
                        onChange={onChangeDatePicker(item?.api_name, record)}
                        data-cy={`tsm-table-${apiName}-datepicker-${record.id}`}
                        data-testid={`tsm-table-${apiName}-datepicker-${record.id}`}
                      />
                    </div>
                  )}
                </>
              ) : (
                grayLabel(value && moment(value).format('YYYY-MM-DD'), false)
              )}
            </Fragment>
          )
        })
      } else if (item.api_name.includes('custom_text')) {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', item.api_name],
          key: item.api_name,
          width: displayTextWidth(item.label),
          render: (value, record) => (
            <Fragment>
              {editKey === record.id && record.task_inactive === 0 ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <Input
                        defaultValue={value}
                        onChange={onChangeInputFieldColumnValueVendorTaskDetails(
                          item.api_name
                        )}
                        data-cy={`tsm-table-${apiName}-input-${record.id}`}
                        data-testid={`tsm-table-${apiName}-input-${record.id}`}
                      />
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            item.api_name,
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              item.api_name,
                              '',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <Input
                      defaultValue={value}
                      onPressEnter={onPressEnterField(
                        item.api_name,
                        record,
                        true
                      )}
                      data-cy={`tsm-table-${apiName}-input-${record.id}`}
                      data-testid={`tsm-table-${apiName}-input-${record.id}`}
                    ></Input>
                  )}
                </>
              ) : (
                grayLabel(value, false)
              )}
            </Fragment>
          )
        })
      } else if (item.api_name.includes('custom_numeric')) {
        columns.push({
          title: item.label,
          dataIndex: ['vendor_task_details', item.api_name],
          key: item.api_name,
          align: 'right',
          width: displayTextWidth(item.label),
          render: (value, record) => (
            <Fragment>
              {editKey === record.id && record.task_inactive === 0 ? (
                <>
                  {selectedRows.length > 1 && isRowSelected(record) ? (
                    <div className='bulk-column'>
                      <InputNumber
                        defaultValue={value}
                        onChange={onChangeInputNumberFieldColumnValueVendorTaskDetails(
                          item.api_name
                        )}
                        data-cy={`tsm-table-${apiName}-input-${record.id}`}
                        data-testid={`tsm-table-${apiName}-input-${record.id}`}
                      />
                      <br />
                      <div className='checkbox-lbl'>
                        <Checkbox
                          defaultChecked={false}
                          onChange={onChangeCheckboxApplyToSelectedRow(
                            item.api_name,
                            record.id
                          )}
                          data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                          data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                        />
                        <label>
                          Update {selectedRows.length} selected items
                        </label>
                      </div>
                      <Fragment>
                        <div className='bulk-buttons'>
                          <Button
                            size='small'
                            onClick={onClickCancel}
                            data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                            ghost
                          >
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                              item.api_name,
                              '',
                              record
                            )}
                            data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                          >
                            Apply
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  ) : (
                    <InputNumber
                      defaultValue={value}
                      onPressEnter={onPressEnterField(
                        item.api_name,
                        record,
                        true
                      )}
                      data-cy={`tsm-table-${apiName}-input-${record.id}`}
                      data-testid={`tsm-table-${apiName}-input-${record.id}`}
                    ></InputNumber>
                  )}
                </>
              ) : (
                grayLabel(formatIfDecimal(value), false)
              )}
            </Fragment>
          )
        })
      } else {
        if (item.source == 'vendor_task_details') {
          // If within VTD, omit `g_` when the api key starts with `g_`
          columns.push({
            title: item.label,
            dataIndex: [
              'vendor_task_details',
              item.api_name.startsWith('g_')
                ? item.api_name.replace('g_', '')
                : item.api_name
            ],
            key: item.api_name.startsWith('g_')
              ? item.api_name.replace('g_', '')
              : item.api_name,
            width: displayTextWidth(item.label),
            render: (item) => (
              <Fragment>
                <span>{grayLabel(item, true)}</span>
              </Fragment>
            )
          })
        } else if (item.api_name == 'service_start_date') {
          columns.push({
            title: item.label,
            dataIndex: ['vendor_task_details', 'service_start_date'],
            key: 'service_start_date',
            width: displayTextWidth(item.label),
            render: (item, record) => (
              <Fragment>
                {editKey === record.id &&
                isPOEditableNewRule(record) &&
                record.task_inactive === 0 ? (
                  <>
                    {selectedRows.length > 1 && isRowSelected(record) ? (
                      <div className='bulk-column'>
                        <DatePicker
                          defaultValue={item && moment(item)}
                          onChange={onChangeDatePickerColumnValueVendorTaskDetails(
                            'service_start_date'
                          )}
                          data-cy={`tsm-table-${apiName}-datepicker-${record.id}`}
                          data-testid={`tsm-table-${apiName}-datepicker-${record.id}`}
                        />
                        <br />
                        <div className='checkbox-lbl'>
                          <Checkbox
                            defaultChecked={false}
                            onChange={onChangeCheckboxApplyToSelectedRow(
                              'service_start_date',
                              record.id
                            )}
                            data-cy={`tsm-table-${apiName}-checkbox-${record.id}`}
                            data-testid={`tsm-table-${apiName}-checkbox-${record.id}`}
                          />
                          <label>
                            Update {selectedRows.length} selected items
                          </label>
                        </div>
                        <Fragment>
                          <div className='bulk-buttons'>
                            <Button
                              size='small'
                              onClick={onClickCancel}
                              data-cy={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                              data-testid={`tsm-table-${apiName}-cancel-btn-${record.id}`}
                              ghost
                            >
                              Cancel
                            </Button>
                            <Button
                              type='primary'
                              size='small'
                              onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
                                'service_start_date',
                                '',
                                record
                              )}
                              data-cy={`tsm-table-${apiName}-apply-btn-${record.id}`}
                              data-testid={`tsm-table-${apiName}-apply-btn-${record.id}`}
                            >
                              Apply
                            </Button>
                          </div>
                        </Fragment>
                      </div>
                    ) : (
                      <div>
                        <DatePicker
                          defaultValue={item && moment(item)}
                          onChange={onChangeDatePicker(
                            'service_start_date',
                            record
                          )}
                          data-cy={`tsm-table-${apiName}-datepicker-${record.id}`}
                          data-testid={`tsm-table-${apiName}-datepicker-${record.id}`}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  grayLabel(
                    item && moment(item).format('YYYY-MM-DD'),
                    !isPOEditableNewRule(record)
                  )
                )}
              </Fragment>
            )
          })
        } else if (item.api_name !== 'is_out_of_sync') {
          columns.push({
            title: item.label,
            dataIndex: item.api_name,
            key: item.api_name,
            width: displayTextWidth(item.label),
            render: (item) => (
              <Fragment>
                <span>{item?.api_name}</span>
                <span>{grayLabel(item, true)}</span>
              </Fragment>
            )
          })
        }
      }

      return columns
    })

    columns = columns.map((column, index) => {
      if (index <= 4) return { ...column, fixed: 'left' }
      else return column
    })

    columns = columns.map((column) => {
      return {
        ...column,
        sorter: column.key !== '',
        title: column.title,
        sortDirections: column.key !== '' && ['ascend', 'descend', 'ascend']
      }
    })

    return columns
  }

  //AC-29 only copied task are editable fields
  const isPOEditableNewRule = (record) => {
    return (
      (record.task_type === 'Vendor' ||
        record.task_type === 'Vendor Copy' ||
        record.task_type === 'PO Copy') &&
      record.task_inactive == 0
    )
  }

  /* Start: A C T I O N  B U T T O N S */
  const onClickRemove = (item) => async () => {
    deleteVendorTasks(companyId, token, item.id).then((res) => {
      if (res) {
        let tempList = tasks
        let index = tempList.indexOf(item)

        if (index > -1) {
          tempList = tempList.filter((data, idx) => idx !== index)
          dispatch(setTasks(tempList))
          message.success('Record was successfully removed.')
        }
      } else {
        message.error('Error in removing the record.')
      }
    })
  }

  const onClickCopy = (item, record) => () => {
    copyVendorTasks(item.id).then((res) => {
      if (res) {
        const { data } = res

        let tempList = tasks
        let total = parseInt(totalTasks) + 1
        let index = tasks.indexOf(record)
        if (index > -1) {
          tempList = addAfter(tempList, index + 1, data) //insert below item

          if (tempList.length == 101 && index == tempList.length - 2) {
            //check if copying last item of page and if it is already the max item per page
            setTotalItemPerPage(tempList.length) //update totalItem per page to insert temp the item
          } else if (tempList.length > 100) {
            tempList.pop()
          }
          dispatch(setTotalTasks(total))
          dispatch(setTasks(tempList))
          message.success('Record was successfully copied.')
        }
      } else {
        message.error('Error in copying the record.')
      }
    })
  }

  const addAfter = (array, index, newItem) => {
    return [...array.slice(0, index), newItem, ...array.slice(index)]
  }

  const onClearSelector = (key, row, isDetails, keyId) => () => {
    let vendorData = {}
    let detailsData = {}
    let rowId = row.id

    if (isDetails) {
      let detailsId = row.vendor_task_details.id
      if (key === 'name') {
        vendorData = {
          [keyId]: null
        }

        detailsData = {
          [key]: null
        }

        row.vendor_task_details[key] = null
        row.vendor_id = null
        updateVendorTasks(companyId, token, rowId, vendorData).then(
          (response) => {
            if (response && response.data) {
              updateVendorDetailsTasks(
                companyId,
                token,
                detailsId,
                detailsData
              ).then((res) => {
                if (res.data) {
                  dispatch(
                    setTasks(
                      tasks.map((task) => {
                        if (task.id === row.id) {
                          return {
                            ...task,
                            ...response.data,
                            vendor_task_details: {
                              ...task?.vendor_task_details,
                              ...res.data
                            }
                          }
                        } else return task
                      })
                    )
                  )
                  message.success('Record was successfully updated.')
                } else {
                  message.error('Error in updating the record.')
                }
              })
            } else {
              message.error('Error in updating the record.')
            }
          }
        )
      } else {
        detailsData = {
          [key]: null,
          [keyId]: null
        }

        row.vendor_task_details[key] = null
        row.vendor_task_details[keyId] = null
      }
      if (key !== 'name') {
        updateVendorDetailsTasks(companyId, token, detailsId, detailsData).then(
          (res) => {
            if (res && res.data) {
              message.success('Record was successfully updated.')
              dispatch(
                setTasks(
                  tasks.map((task) => {
                    if (task.id === row.id) {
                      return {
                        ...task,
                        is_out_of_sync: res.data.is_out_of_sync,
                        vendor_task_details: {
                          ...task?.vendor_task_details,
                          ...res.data
                        }
                      }
                    } else return task
                  })
                )
              )
              if (key == 'subsidiary') {
                // to fetch subsidiary currency onchange
                getTaskList({
                  ...taskParams,
                  filter: activeFilterRef.current.id,
                  page: currPage,
                  sort:
                    columnToBeSortedRef.current.direction !== ''
                      ? `${columnToBeSortedRef.current.columnName}:${columnToBeSortedRef.current.direction}`
                      : null,
                  term: searchTermRef.current
                })
              }
            } else {
              message.error('Error in updating the record.')
            }
          }
        )
      }
    } else {
      vendorData = {
        [key]: null
      }
      row[key] = null

      updateVendorTasks(companyId, token, rowId, vendorData).then(
        (response) => {
          if (response && response.data) {
            dispatch(
              setTasks(
                tasks.map((task) => {
                  if (task.id === rowId) return { ...task, ...response.data }
                  else return task
                })
              )
            )
            message.success('Record was successfully updated.')
          } else {
            message.error('Error in updating the record.')
          }
        }
      )
    }
  }

  const onChangeSelector = (key, row, isDetails, keyId) => (value, option) => {
    let vendorData = {}
    let detailsData = {}
    let rowId = row.id

    if (isDetails) {
      let detailsId = row.vendor_task_details.id
      if (key === 'name') {
        vendorData = {
          [keyId]: option.key
        }

        detailsData = {
          [key]: value
        }

        row.vendor_task_details[key] = value
        row.vendor_id = option.key
        updateVendorTasks(companyId, token, rowId, vendorData).then(
          (response) => {
            if (response && response.data) {
              updateVendorDetailsTasks(
                companyId,
                token,
                detailsId,
                detailsData
              ).then((res) => {
                if (res.data) {
                  dispatch(
                    setTasks(
                      tasks.map((task) => {
                        if (task.id === row.id) {
                          return {
                            ...task,
                            ...response.data,
                            vendor_task_details: {
                              ...task?.vendor_task_details,
                              ...res.data
                            }
                          }
                        } else return task
                      })
                    )
                  )
                  message.success('Record was successfully updated.')
                } else {
                  message.error('Error in updating the record.')
                }
              })
            } else {
              message.error('Error in updating the record.')
            }
          }
        )
      } else {
        detailsData = {
          [key]: option.children == undefined ? null : option.children, // for choosing empty
          [keyId]: option.value
        }

        row.vendor_task_details[key] = option.children
        row.vendor_task_details[keyId] = option.value
      }
      if (key !== 'name') {
        updateVendorDetailsTasks(companyId, token, detailsId, detailsData).then(
          (res) => {
            if (res && res.data) {
              dispatch(
                setTasks(
                  tasks.map((task) => {
                    if (task.id === row.id) {
                      return {
                        ...task,
                        is_out_of_sync: res.data.is_out_of_sync,
                        vendor_task_details: {
                          ...task?.vendor_task_details,
                          ...res.data
                        }
                      }
                    } else return task
                  })
                )
              )
              message.success('Record was successfully updated.')
              if (key == 'subsidiary') {
                // getTaskList({ page: currPage, ...taskParams }) // to fetch subsidiary currency onchange
                getTaskList({
                  ...taskParams,
                  filter: activeFilterRef.current.id,
                  page: currPage,
                  sort:
                    columnToBeSortedRef.current.direction !== ''
                      ? `${columnToBeSortedRef.current.columnName}:${columnToBeSortedRef.current.direction}`
                      : null,
                  term: searchTermRef.current
                })
              }
            } else {
              message.error('Error in updating the record.')
            }
          }
        )
      }
    } else {
      vendorData = {
        [key]: value
      }
      row[key] = value

      updateVendorTasks(companyId, token, rowId, vendorData).then(
        (response) => {
          if (response && response.data) {
            dispatch(
              setTasks(
                tasks.map((task) => {
                  if (task.id === row.id) return { ...task, ...response.data }
                  else return task
                })
              )
            )
            message.success('Record was successfully updated.')
          } else {
            message.error('Error in updating the record.')
          }
        }
      )
    }
  }

  const onChangeSwitch = (key, row, previousStatus) => (e) => {
    let value = e
    row[key] = value ? '1' : '0'

    let data = {
      [key]: value ? 1 : 0
    }
    updateVendorTasks(companyId, token, row.id, data).then((res) => {
      if (res.data) {
        if (res.data[key] !== previousStatus)
          message.success('Record was successfully updated.')
        dispatch(
          setTasks(
            tasks.map((task) => {
              if (task.id === row.id) return { ...task, ...res.data }
              else return task
            })
          )
        )
      } else {
        message.error('Error in updating the record.')
      }
    })
  }

  const onChangeCheckbox = (key, row) => (e) => {
    let value = e.target.checked
    row[key] = value ? '1' : '0'

    let data = {
      [key]: value ? 1 : 0
    }
    updateVendorTasks(companyId, token, row.id, data).then((res) => {
      if (res.data) {
        dispatch(
          setTasks(
            tasks.map((task) => {
              if (task.id === row.id) return { ...task, ...res.data }
              else return task
            })
          )
        )
        message.success('Record was successfully updated.')
      } else {
        message.error('Error in updating the record.')
      }
    })
  }

  const onPressEnterField = (key, row, isDetails) => (e) => {
    let value = e.target.value

    let data = {
      [key]: value
    }

    // Regular expression pattern for email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    // If the edited field is an email type, check proper formatting
    if (
      // Will temporarily remove cc_email and internal_cc_email for https://gappify.atlassian.net/browse/AC-1290
      ['email', 'internal_email'].includes(key) &&
      !emailPattern.test(value) &&
      value.length > 0
    ) {
      message.error('Incorrect email format.')
    } else if (isDetails) {
      let detailsId = row.vendor_task_details.id
      row.vendor_task_details[key] = value
      updateVendorDetailsTasks(companyId, token, detailsId, data).then(
        (response) => {
          if (response && response.data) {
            dispatch(
              setTasks(
                tasks.map((task) => {
                  if (task.id === row.id)
                    return {
                      ...task,
                      is_out_of_sync: response.data.is_out_of_sync,
                      vendor_task_details: {
                        ...task?.vendor_task_details,
                        ...response.data
                      }
                    }
                  else return task
                })
              )
            )
            message.success('Record was successfully updated.')
          } else {
            message.error('Error in updating the record.')
          }
        }
      )
    } else {
      let rowId = row.id
      row[key] = value
      updateVendorTasks(companyId, token, rowId, data).then((res) => {
        if (res && res.data) {
          dispatch(
            setTasks(
              tasks.map((task) => {
                if (task.id === row.id) return { ...task, ...res.data }
                else return task
              })
            )
          )
          message.success('Record was successfully updated.')
        } else {
          message.error('Error in updating the record.')
        }
      })
    }
  }

  const onChangeDatePicker = (key, row) => (date) => {
    let detailsId = row.vendor_task_details.id

    let data = {
      [key]: date ? moment(date).format('YYYY-MM-DD') : null
    }
    row.vendor_task_details[key] = date
    updateVendorDetailsTasks(companyId, token, detailsId, data).then(
      (response) => {
        if (response && response.data) {
          dispatch(
            setTasks(
              tasks.map((task) => {
                if (task.id === row.id)
                  return {
                    ...task,
                    is_out_of_sync: response.data.is_out_of_sync,
                    vendor_task_details: {
                      ...task?.vendor_task_details,
                      ...response.data
                    }
                  }
                else return task
              })
            )
          )
          message.success('Record was successfully updated.')
        } else {
          message.error('Error in updating the record.')
        }
      }
    )
  }

  const columnToBeSortedRef = useRef({ columnName: '', direction: '' })

  const onChangeTable = async (pagination, _, col) => {
    columnToBeSortedRef.current = {
      columnName: col.columnKey === 'vendor_name' ? 'name' : col.columnKey,
      direction:
        col.order === 'ascend' ? 'asc' : col.order === 'descend' ? 'desc' : ''
    }

    let curPage = pagination.current

    let params = {
      ...taskParams,
      page: curPage,
      sort:
        columnToBeSortedRef.current.direction !== ''
          ? `${columnToBeSortedRef.current.columnName}:${columnToBeSortedRef.current.direction}`
          : null,
      term: searchTermRef.current
    }

    if (tsmPreviewMode) {
      params.previewMode = true
      params.filter_fields = tsmFilterFields
    } else {
      params.filter = activeFilterRef.current.id
    }

    setApplyToSelectedRows([])
    setSelectedRows([])
    setEditKey('')
    dispatch(setCurrPage(curPage))
    dispatch(setIsLoading(true))

    getTaskList(params)
  }

  const getUserAccessRole = async () => {
    setHasEditRole(RP.USER_UPDATE_TASK_PERMISSION)
  }

  /* Ascending Order Sorting based on value */
  //const sortAsc = (optionA, optionB) => (optionA.value > optionB.value) ? 1 : -1;

  /* Case Insensitive Sorting */
  const sortAsc = function (optionA, optionB) {
    if (optionA.value && optionB.value) {
      return optionA.value
        .toLowerCase()
        .localeCompare(optionB.value.toLowerCase())
    } else {
      return 0
    }
  }

  const resendRequest = async (params) => {
    const _response = params?.previewMode
      ? await previewFilter(params)
      : await getVendorTasks(params)

    if (_response && _response.data) {
      dispatch(setIsLoading(false))
      dispatch(setTasks(_response.data))
      dispatch(setTotalTasks(_response.headers['x-total-count']))
      dispatch(setIsLoading(false))
      setShowHeader(true)

      return _response
    } else {
      dispatch(setTasks([]))
      dispatch(setIsLoading(false))
      setShowHeader(false)

      return null
    }
  }

  /* Start: A P I  C A L L S */
  const getTaskList = async (params) => {
    const response = params?.previewMode
      ? await previewFilter(params)
      : await getVendorTasks(params)
    if (response && response.data) {
      // if page num is impossible (per num of records), return to page 1
      const totalCount = parseInt(response.headers['x-total-count'])
      const maxPages = Math.ceil(totalCount / 100)

      if (currPage > maxPages && maxPages !== 0) {
        dispatch(setIsLoading(true))
        dispatch(setCurrPage(1))
        params.page = 1
        return resendRequest(params)
      }

      setTotalItemPerPage(100)
      dispatch(setTasks(response.data))
      dispatch(setTotalTasks(totalCount))
      dispatch(setIsLoading(false))
      setShowHeader(true)

      return response
    } else {
      dispatch(setTasks([]))
      dispatch(setIsLoading(false))
      setShowHeader(false)
    }
  }

  const getVendorList = async () => {
    const response = await getSourceVendorOptions(companyId, token)
    if (response && response.data) {
      setVendorList(response.data.sort(sortAsc))
    } else {
      setVendorList([])
    }
  }

  const getFormList = async () => {
    const response = await getFormsOptions(companyId, token)

    if (response && response.data) {
      setFormList(response.data.sort(sortAsc))
    }
  }

  const getCompanyList = async () => {
    const response = await getSourceCoaOptions('Company')

    if (response && response.data && response.data.Company) {
      setCompanyList(response.data.Company.sort(sortAsc))
    }
  }

  const getSubsidiaryList = async () => {
    const response = await getSourceCoaOptions('Subsidiary')

    if (response && response.data && response.data.Subsidiary) {
      setSubsidiaryList(response.data.Subsidiary.sort(sortAsc))
    }
  }

  const getDeptList = async () => {
    const response = await getSourceCoaOptions('Department')

    if (response && response.data && response.data.Department) {
      setDeptList(response.data.Department.sort(sortAsc))
    }
  }

  const getGlAccountList = async () => {
    const response = await getSourceCoaOptions('GLAccount')

    if (response && response.data && response.data.GLAccount) {
      setGlAccountList(response.data.GLAccount.sort(sortAsc))
    }
  }

  const getInterCompanyList = async () => {
    const response = await getSourceCoaOptions('InterCompany')

    if (response && response.data && response.data.Intercompany) {
      setInterCompanyList(response.data.Intercompany.sort(sortAsc))
    }
  }

  const getChannelList = async () => {
    const response = await getSourceCoaOptions('Channel')

    if (response && response.data && response.data.Channel) {
      setChannelList(response.data.Channel.sort(sortAsc))
    }
  }

  const getLocationList = async () => {
    const response = await getSourceCoaOptions('Location')

    if (response && response.data && response.data.Location) {
      setLocationList(response.data.Location.sort(sortAsc))
    }
  }

  const getProductList = async () => {
    const response = await getSourceCoaOptions('Product')

    if (response && response.data && response.data.Product) {
      setProductList(response.data.Product.sort(sortAsc))
    }
  }

  const getRegionList = async () => {
    const response = await getSourceCoaOptions('Region')

    if (response && response.data && response.data.Region) {
      setRegionList(response.data.Region.sort(sortAsc))
    }
  }

  const getCurrencyList = async () => {
    const response = await getSourceCoaOptions('Currency')

    if (response && response.data && response.data.Currency) {
      setCurrencyList(response.data.Currency.sort(sortAsc))
    }
  }

  const getClassList = async () => {
    const response = await getSourceCoaOptions('Class')

    if (response && response.data && response.data.Class) {
      setClassList(response.data.Class.sort(sortAsc))
    }
  }

  const getProjectList = async () => {
    const response = await getSourceCoaOptions('Project')

    if (response && response.data && response.data.Project) {
      setProjectList(response.data.Project.sort(sortAsc))
    }
  }

  const getExternalList = async () => {
    const response = await getSourceCoaOptions('External')

    if (response && response.data && response.data.External) {
      setExternalList(response.data.External.sort(sortAsc))
    }
  }

  const getInternalList = async () => {
    const response = await getSourceCoaOptions('Internal')

    if (response && response.data && response.data.Internal) {
      setInternalList(response.data.Internal.sort(sortAsc))
    }
  }

  useEffect(() => {
    getFormList()
    getUserAccessRole()
    getColumnHeaders()
    getTaskList({ filter: 'unreviewed' })
    getExternalList()
    getInternalList() // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeCheckboxAll = (rows) => (e) => {
    const isChecked = e.target.checked

    if (!isChecked) {
      setSelectedRows([])
      return
    }
    setSelectedRows(
      rows.filter((item) => item.task_inactive === 0).map((item) => item.id)
    )
  }

  const onChangeSelectCheckbox = (row) => (e) => {
    const isChecked = e.target.checked

    if (!isChecked) {
      setSelectedRows(selectedRows.filter((item) => item !== row.id))
      return
    }
    setSelectedRows([...selectedRows, row.id])
  }

  const isRowSelected = (row) => selectedRows.includes(row.id)

  const onChangeSetColumnValue = (key) => (e) => {
    const value = e.target.value
    setColumnValue({ key, value })
    bulkUpdateDataRef.current[key] = value
  }

  // for active switch bulk update
  const onSwitchSetColumnValue = (key) => (e) => {
    setColumnValue({ key, value: e ? 1 : 0 })
    bulkUpdateDataRef.current[key] = e ? 1 : 0
  }

  const onChangePickListSetColumnValue = (key) => (value) => {
    setColumnValue({ key, value })
    bulkUpdateDataRef.current[key] = value
  }

  const onChangePickListSetColumnValueVendorTaskDetails =
    (key, keyId) => (value, option) => {
      setColumnValueTaskDetails({
        [key]: option.children,
        [keyId]: value
      })
      bulkUpdateDataRef.current[key] = option.children
      bulkUpdateDataRef.current[keyId] = value
    }

  const onChangeColumnVendorName = (key, keyId) => (value, option) => {
    setColumnVendorName({
      [keyId]: option.key,
      [key]: value
    })
    bulkUpdateDataRef.current[keyId] = option.key
    bulkUpdateDataRef.current[key] = value
  }

  const onChangeInputFieldColumnValueVendorTaskDetails =
    (key, keyId) => (e) => {
      const value = e.target.value
      if (keyId) {
        //
      }
      setColumnValueTaskDetails({ [key]: value })
      bulkUpdateDataRef.current[key] = value
    }

  const onChangeInputNumberFieldColumnValueVendorTaskDetails =
    (key, keyId) => (value) => {
      setColumnValueTaskDetails({ [key]: value })
      bulkUpdateDataRef.current[key] = value
    }

  const onChangeDatePickerColumnValueVendorTaskDetails =
    (key, keyId) => (date) => {
      const value = date ? moment(date).format('YYYY-MM-DD') : null
      if (keyId) {
        //
      }
      setColumnValueTaskDetails({ [key]: value })
      bulkUpdateDataRef.current[key] = value
    }

  const onChangeInputField = (key) => (e) => {
    const value = e.target.value
    setColumnValue({ key, value })
    bulkUpdateDataRef.current[key] = value
  }

  const onChangeCheckboxApplyToSelectedRow = (key, id) => (e) => {
    const itemExists = applyToSelectedRows.find(
      (item) => item.id === id && item.key === key
    )

    if (applyToSelectedRows.length < 1 || !Boolean(itemExists)) {
      const row = {
        id,
        key,
        selectAll: e.target.checked
      }
      setApplyToSelectedRows([...applyToSelectedRows, row])
      return
    }

    setApplyToSelectedRows(applyToSelectedRows.filter((row) => row.key !== key))
  }

  const onClickApplyChangesToSelectedRows =
    (key, row, itemData) => async () => {
      const value =
        bulkUpdateDataRef.current[key] === null
          ? itemData
          : bulkUpdateDataRef.current[key] === null
          ? columnValue.value
          : bulkUpdateDataRef.current[key]

      const dataKey = key || columnValue.key

      const getRowField = applyToSelectedRows.find(
        (item) => item.id == row.id && item.key == key
      )

      if (Boolean(getRowField)) {
        const selectedEditableRows =
          hasEditableKeys.includes(key) || key.includes('custom')
            ? selectedRows.filter((item) => {
                const task = tasks.find((task) => task.id === item)
                if (key == 'internal_email' || key == 'internal_cc_email') {
                  return isEditableNewRule(task)
                }
                return isEditableNewRule(task)
              })
            : selectedRows

        const data = {
          ids: selectedEditableRows,
          [dataKey]: value
        }

        try {
          dispatch(setIsLoading(true))
          let tasksCopy = JSON.parse(JSON.stringify(tasks))
          let res = await massUpdateVendorTasks(data)
          await res?.data?.updatedRows?.forEach((updatedRow) => {
            tasksCopy.forEach((task, index) => {
              if (task.id == updatedRow.id) {
                tasksCopy[index] = { ...task, ...updatedRow }
              } else tasksCopy[index] = task
            })
          })
          dispatch(setTasks(tasksCopy))
          setSelectedRows([])
          setColumnValue({ key: '', value: null })
          setColumnValueTaskDetails({})
          bulkUpdateDataRef.current = {}
          setApplyToSelectedRows([])
          dispatch(setIsLoading(false))
          message.success('Record was successfully updated.')
        } catch (err) {
          dispatch(setIsLoading(false))
          message.error('Error in updating the record.')
        } finally {
          setEditKey('')
        }
      } else {
        const data = {
          [dataKey]: value
        }

        try {
          dispatch(setIsLoading(true))
          await updateVendorTasks(companyId, token, row.id, data)
          // await getTaskList({ page: currPage, ...taskParams })
          await getTaskList({
            ...taskParams,
            filter: activeFilterRef.current.id,
            page: currPage,
            sort:
              columnToBeSortedRef.current.direction !== ''
                ? `${columnToBeSortedRef.current.columnName}:${columnToBeSortedRef.current.direction}`
                : null,
            term: searchTermRef.current
          })
          setSelectedRows([])
          setColumnValue({ key: '', value: null })
          setColumnValueTaskDetails({})
          bulkUpdateDataRef.current = {}
          setApplyToSelectedRows([])
          dispatch(setIsLoading(false))
          message.success('Record was successfully updated.')
        } catch (err) {
          message.error('Error in updating the record.')
        } finally {
          setEditKey('')
        }
      }
    }

  const onClickApplyChangesToSelectedRowsVendorTaskDetails =
    (key, keyId, row) => async () => {
      const item = {
        [key]: bulkUpdateDataRef.current[key]
      }

      if (keyId) {
        item[keyId] = bulkUpdateDataRef.current[keyId]
      }

      const value = item || columnValueVendorTaskDetails

      const getRowField = applyToSelectedRows.find(
        (item) => item.id == row.id && item.key == key
      )

      if (Boolean(getRowField)) {
        const selectedEditableRows =
          hasEditableKeys.includes(key) || key.includes('custom')
            ? selectedRows.filter((item) => {
                const task = tasks.find((task) => task.id === item)
                return isEditableNewRule(task)
              })
            : selectedRows

        const detailsId = selectedEditableRows.map((item) => {
          const task = tasks.find((task) => task.id === item)
          return task.vendor_task_details.id ?? task.vendor_task_details.id
        })

        const data = {
          ids: detailsId,
          ...value
        }

        try {
          dispatch(setIsLoading(true))
          let tasksCopy = JSON.parse(JSON.stringify(tasks))
          let res = await massUpdateVendorTasksDetails(data)
          await res?.data?.updatedRows?.forEach((updatedRow) => {
            tasksCopy.forEach((task, index) => {
              if (task.id == updatedRow.id) {
                tasksCopy[index] = { ...task, ...updatedRow }
              } else tasksCopy[index] = task
            })
          })
          dispatch(setTasks(tasksCopy))
          setSelectedRows([])
          setColumnValue({ key: '', value: null })
          setColumnValueTaskDetails({})
          bulkUpdateDataRef.current = {}
          setApplyToSelectedRows([])
          dispatch(setIsLoading(false))
          message.success('Record was successfully updated.')
        } catch (err) {
          dispatch(setIsLoading(false))
          message.error('Error in updating the record.')
        } finally {
          setEditKey('')
        }
      } else {
        const data = { ...value }

        try {
          dispatch(setIsLoading(true))
          await updateVendorDetailsTasks(companyId, token, row.id, data)
          // await getTaskList({ page: currPage, ...taskParams })
          await getTaskList({
            ...taskParams,
            filter: activeFilterRef.current.id.trim(),
            page: currPage,
            sort:
              columnToBeSortedRef.current.direction !== ''
                ? `${columnToBeSortedRef.current.columnName}:${columnToBeSortedRef.current.direction}`
                : null,
            term: searchTermRef.current
          })
          setSelectedRows([])
          setColumnValue({ key: '', value: null })
          setColumnValueTaskDetails({})
          bulkUpdateDataRef.current = {}
          setApplyToSelectedRows([])
          dispatch(setIsLoading(false))
          message.success('Record was successfully updated.')
        } catch (err) {
          message.error('Error in updating the record.')
        } finally {
          setEditKey('')
        }
      }
    }

  const onClickApplyChangesToSelectedRowsVendorName =
    (key, keyId, row) => async () => {
      const vendorId =
        columnVendorName.vendor_id === null
          ? row.vendor_id
          : columnVendorName.vendor_id

      const vendorName =
        columnVendorName.name === ''
          ? row.vendor_task_details.name
          : columnVendorName.name

      const getRowField = applyToSelectedRows.find(
        (item) => item.id == row.id && item.key == key
      )
      if (Boolean(getRowField)) {
        const selectedEditableRows =
          hasEditableKeys.includes(key) || key.includes('custom')
            ? selectedRows.filter((item) => {
                const task = tasks.find((task) => task.id === item)
                return isEditable(task) && !task.internal_source_vendor_id
              })
            : selectedRows

        const detailsIds = selectedEditableRows.map((item) => {
          const task = tasks.find((task) => task.id === item)
          return task.vendor_task_details.id ?? task.vendor_task_details.id
        })

        const vendorData = {
          ids: selectedEditableRows,
          vendor_id: vendorId
        }

        const vendorDetailsData = {
          ids: detailsIds,
          name: vendorName
        }

        try {
          dispatch(setIsLoading(true))
          let tasksCopy = JSON.parse(JSON.stringify(tasks))
          let res = await massUpdateVendorTasks(vendorData)
          await res?.data?.updatedRows?.forEach((updatedRow) => {
            tasksCopy.forEach((task, index) => {
              if (task.id == updatedRow.id) {
                tasksCopy[index] = { ...task, ...updatedRow }
              } else tasksCopy[index] = task
            })
          })
          let _res = await massUpdateVendorTasksDetails(vendorDetailsData)
          await _res?.data?.updatedRows?.forEach((updatedRow) => {
            tasksCopy.forEach((task, index) => {
              if (task.id == updatedRow.id) {
                tasksCopy[index] = { ...task, ...updatedRow }
              } else tasksCopy[index] = task
            })
          })
          dispatch(setTasks(tasksCopy))
          dispatch(setIsLoading(false))
          message.success('Record was successfully updated.')
        } catch (error) {
          dispatch(setIsLoading(false))
          message.error('Error in updating the record.')
        } finally {
          setEditKey('')
          setColumnVendorName({ name: '', vendor_id: null })
          setSelectedRows([])
          setColumnValueTaskDetails({})
          bulkUpdateDataRef.current = {}
          setApplyToSelectedRows([])
        }
      } else {
        const vendorData = {
          vendor_id: vendorId
        }

        const vendorDetailsData = {
          name: vendorName
        }

        try {
          dispatch(setIsLoading(true))
          await updateVendorTasks(companyId, token, row.id, vendorData)
          await updateVendorDetailsTasks(
            companyId,
            token,
            row.id,
            vendorDetailsData
          )
          // await getTaskList({ page: currPage, ...taskParams })
          await getTaskList({
            ...taskParams,
            filter: activeFilterRef.current.id.trim(),
            page: currPage,
            sort:
              columnToBeSortedRef.current.direction !== ''
                ? `${columnToBeSortedRef.current.columnName}:${columnToBeSortedRef.current.direction}`
                : null,
            term: searchTermRef.current
          })
          dispatch(setIsLoading(false))
          message.success('Record was successfully updated.')
        } catch (error) {
          dispatch(setIsLoading(false))
          message.error('Error in updating the record.')
        } finally {
          setEditKey('')
          setColumnVendorName({ name: '', vendor_id: null })
          setSelectedRows([])
          setColumnValueTaskDetails({})
          bulkUpdateDataRef.current = {}
          setApplyToSelectedRows([])
        }
      }
    }

  const onClickCancel = () => {
    setEditKey('')
    setColumnVendorName({ name: '', vendor_id: null })
    setColumnValueTaskDetails({})
    bulkUpdateDataRef.current = {}
    setApplyToSelectedRows([])
  }

  return (
    <Fragment>
      <div className='table-content-wrapper'>
        <TaskManagerFilter
          columns={columnHeaders}
          externalOptions={externalList}
          internalOptions={internalList}
          activeFilterRef={activeFilterRef}
          reloadData={(additionalPayload = {}) => {
            return getTaskList({
              ...taskParams,
              ...additionalPayload,
              sort:
                columnToBeSortedRef.current.direction !== ''
                  ? `${columnToBeSortedRef.current.columnName}:${columnToBeSortedRef.current.direction}`
                  : null
            })
          }}
          columnToBeSortedRef={columnToBeSortedRef}
          totalTasks={totalTasks}
          setApplyToSelectedRows={setApplyToSelectedRows}
          setSelectedRows={setSelectedRows}
          setEditKey={setEditKey}
          searchTermRef={searchTermRef}
        />
        <DyanmicTable
          showSorterTooltip={false}
          columnHeader={setColumns()}
          data={tasks}
          scroll={{ x: 'max-content', y: 600 }}
          loading={isLoading}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                if (hasEditRole) {
                  setEditKey(record.id)
                }
              },

              onClick: () => {
                if (editKey != record.id) {
                  setEditKey('')
                  setApplyToSelectedRows([])
                  setColumnValue({ key: '', value: null })
                  setColumnValueTaskDetails({})
                  bulkUpdateDataRef.current = {}
                }
              }
            }
          }}
          pagination={{
            pageSize: totalItemPerPage,
            total: totalTasks,
            showSizeChanger: false,
            defaultCurrent: 1,
            current: currPage,
            showTotal: (total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total}`
          }}
          onChange={onChangeTable}
          showHeader={showHeader}
        />
      </div>
    </Fragment>
  )
}

export default TaskManagerTable

import { FC } from 'react'
import {
  DownOutlined,
  ExportOutlined,
  PlusOutlined,
  SearchOutlined
} from '@ant-design/icons'
import { Col, Input, Row, Select, Space } from 'antd'
import GButton from '../../../../../components/gappify/GButton'
import GDropdownBtn from '../../../../../components/gappify/GDropdownBtn'

// ** Helpers imports **
import getRoleOptions from '../helpers/getRoleOptions_q3'

interface UserDashBoardHeaderProps {
  onClickAddUser?: () => void
  onClickExport?: (event: {
    key: string
    keyPath: string[]
    item: React.ReactInstance
    domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  }) => void
  onChangeSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDownSearch?: ({ key }: any) => void
  onSelectStatus?: (value: string) => void
  onSelectRoles?: (value: number[]) => void
  loadingExport?: boolean
}

const UserDashBoardHeader: FC<UserDashBoardHeaderProps> = ({
  onClickAddUser,
  onClickExport,
  onChangeSearch,
  onKeyDownSearch,
  onSelectStatus,
  onSelectRoles,
  loadingExport
}: UserDashBoardHeaderProps): JSX.Element => {
  return (
    <Row
      data-testid='user-dashboard-header'
      data-cy='user-dashboard-header'
      className='user-dash-header'
    >
      <Col
        sm={24}
        lg={5}
        style={{ marginRight: '20px', justifyContent: 'space-between' }}
      >
        <Input
          data-testid='user-dashboard-search-input-field'
          data-cy='user-dashboard-search-input-field'
          placeholder='Search users by name, email, etc.'
          prefix={
            <SearchOutlined
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
          }
          style={{ borderRadius: '32px' }}
          allowClear
          onChange={onChangeSearch}
          onKeyDown={onKeyDownSearch}
        />
      </Col>
      <Col sm={24} lg={14}>
        <Space align='center'>
          <span>Filter to:</span>
          <Select
            data-testid='user-dashboard-status-filter'
            data-cy='user-dashboard-status-filter'
            defaultValue='all'
            style={{ width: 120 }}
            options={[
              {
                value: 'all',
                label: 'All users'
              },
              {
                value: 'Active',
                label: 'Active'
              },
              {
                value: 'Inactive',
                label: 'Inactive'
              },
              {
                value: 'Pending',
                label: 'Pending'
              }
            ]}
            onChange={onSelectStatus}
          />
          <Select
            data-testid='user-dashboard-roles-filter'
            data-cy='user-dashboard-roles-filter'
            mode='multiple'
            placeholder='Select Role'
            style={{ minWidth: 250, maxWidth: 'calc(100% - 100px)' }}
            allowClear
            options={getRoleOptions()}
            onChange={onSelectRoles}
            filterOption={(input, option) =>{
                if (option?.label.toLowerCase().includes(input.toLowerCase())) 
                {
                    return true;
                } 
                else 
                {
                    return false;
                }
            }}
            // filterSort={(optionA, optionB) => {
            //     console.log(optionA.label,'optiona')
            //     console.log(optionB.label)
            //   const filterA = optionA.label.toLowerCase()
            //   const filterB = optionB.label.toLowerCase()
            //   if (filterA < filterB) {
            //     return -1
            //   }
            //   if (filterA > filterB) {
            //     return 1
            //   }
            //   return 0
            // }}
          />
        </Space>
      </Col>
      <Col sm={24} lg={4} className='txn-panel-right'>
        <Space>
          <GButton
            btnText='Add User'
            icon={
              <PlusOutlined
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              />
            }
            onClick={onClickAddUser}
            dataTestId='add-user-btn'
            dataCy='add-user-btn'
          />

          <GDropdownBtn
            dataTestId='export-btn'
            dataCy='export-btn'
            menuItems={[
              {
                label: (
                  <span data-testid='export-csv-btn' data-cy='export-csv-btn'>
                    CSV
                  </span>
                ),
                key: 'csv'
              }
            ]}
            btnText='Export to'
            btnIcon={
              <ExportOutlined
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              />
            }
            children={
              <DownOutlined
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              />
            }
            className='g-btn-default'
            onClick={onClickExport}
            loading={loadingExport}
          />
        </Space>
      </Col>
    </Row>
  )
}

export default UserDashBoardHeader

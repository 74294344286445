/** React Imports */
import { useEffect } from 'react'

/** Antd imports */
import { Card, Typography } from 'antd'

/** Components */
import InvoiceStatuses from './components/InvoiceStatuses'
// import POLineAmount from './components/POLineAmount'

/** Hooks */
import useFetchUnapprovedInvoices from './hooks/useFetchUnapprovedInvoices'
import useUnapprovedInvoicesStore from './manager/unapproved-invoices-store'
import { arraysEqual } from 'src/helpers/isArrayEqual'

/** Styles */

/** Utils */

const { Title } = Typography

const UnapprovedInvoices = () => {
  /** Local states */

  /** Global stores */
  const invoiceStatusesValues = useUnapprovedInvoicesStore(
    (state) => state.invoiceStatusesValues
  )
  const isPOLineInvoicedAmountChecked = useUnapprovedInvoicesStore(
    (state) => state.isPOLineInvoicedAmountChecked
  )
  const setIsDirty = useUnapprovedInvoicesStore((state) => state.setIsDirty)

  /** React queries */
  const {
    data: dataFetchUnapprovedInvoices,
    error: errorFetchUnapprovedInvoices,
    isLoading: isLoadingFetchUnapprovedInvoices,
    isRefetching: isRefetchingFetchUnapprovedInvoices,
    isSuccess: isSuccessFetchUnapprovedInvoices,
    isError: isErrorFetchUnapprovedInvoices
  } = useFetchUnapprovedInvoices()

  /** Helpers */

  /** Use effects */
  useEffect(() => {
    if (isSuccessFetchUnapprovedInvoices && dataFetchUnapprovedInvoices) {
      /**
       * ? DEV NOTES:
       * ? Add additional success handling here if needed
       */
      console.log('Success response from API:', dataFetchUnapprovedInvoices)
    }

    if (isErrorFetchUnapprovedInvoices) {
      /**
       * ? DEV NOTES:
       * ? No expected error scenarios for this query
       * ? Add error handling here if needed
       */
      console.log('Error response from API:', errorFetchUnapprovedInvoices)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingFetchUnapprovedInvoices])

  // Use effect to handle unsaved changes
  useEffect(() => {
    let tempIsDirty = false

    // Initially check for invoice statuses field
    if (
      dataFetchUnapprovedInvoices?.invoice_statuses.length &&
      invoiceStatusesValues.length
    ) {
      const isArraysEqual = arraysEqual(
        dataFetchUnapprovedInvoices.invoice_statuses,
        invoiceStatusesValues
      )
      tempIsDirty = !isArraysEqual
    } else if (
      invoiceStatusesValues.length &&
      !dataFetchUnapprovedInvoices?.invoice_statuses.length
    ) {
      tempIsDirty = true
    }

    // Then check for PO Line Invoice Amount field
    if (!tempIsDirty) {
      tempIsDirty =
        isPOLineInvoicedAmountChecked !==
        (dataFetchUnapprovedInvoices?.po_line_invoice_amount_includes_unapproved_invoices ||
          false)
    }

    setIsDirty(tempIsDirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceStatusesValues, isPOLineInvoicedAmountChecked])

  return (
    <>
      {/* Title */}
      <Title
        level={3}
        data-testid='unrecorded-invoices-header'
        data-cy='unrecorded-invoices-header'
        style={{ marginTop: 36, marginBottom: 15 }}
      >
        Unposted Invoices
      </Title>

      {/* Card */}
      <Card
        data-testid='unrecorded-invoices-card'
        data-cy='unrecorded-invoices-card'
        bodyStyle={{ display: 'flex', flexDirection: 'column', gap: 15 }}
      >
        {/* Individual fields here */}
        <InvoiceStatuses
          invoiceStatuses={dataFetchUnapprovedInvoices?.invoice_statuses || []}
          isLoading={
            isLoadingFetchUnapprovedInvoices ||
            isRefetchingFetchUnapprovedInvoices
          }
        />

        {/* Temporarily hide for AC-2728 revert changes  */}
        {/* Uncomment below to show the checkbox setting */}
        {/* <POLineAmount
          isChecked={
            dataFetchUnapprovedInvoices?.po_line_invoice_amount_includes_unapproved_invoices ||
            false
          }
          isLoading={
            isLoadingFetchUnapprovedInvoices ||
            isRefetchingFetchUnapprovedInvoices
          }
        /> */}
      </Card>
    </>
  )
}

export default UnapprovedInvoices
